import './RecurringDonationMigrationForm.scss';
import OrganizationIdInput from '../../OrganizationIdInput/OrganizationIdInput.jsx';
import ClassyButton from '../../ClassyButton/ClassyButton';
import Select from 'react-select';
import validators from '../../../Helpers/validators';
import PropTypes from 'prop-types';
import constants from '../../../Helpers/constants';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox, ControlLabel, FormControl, FormGroup, Form, HelpBlock } from 'react-bootstrap';

class RecurringDonationMigrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: uuidv4(),
      createdAt: Date.now(),
      organizationId: '',
      organizationIdLoading: false,
      label: '',
      submissionType: constants.RECURRING_DONATION_MIGRATION_OPTIONS[0],
      donorFile: null,
      tokenFile: null,
      isDryRun: true,
      validated: false,
      tokenFileError: null,
      userFileError: null,
      labelError: null,
      submitDisabled: false,
      //clientValidationErrors: {}
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ validated: true });
    const isValid = await this.isFormValid();
    if (isValid) {
      this.setState({ submitDisabled: true });
      const job = this.getJob();

      try {
        await this.props.onSubmit(job);
        this.setState({ submitDisabled: false });
      } catch (e) {
        // Additional Error Handling
        this.setState({ submitDisabled: false });
      }
    } else {
      let message = 'Please fix the errors and fill the required fields before submitting the file';
      if (this.state.organizationIdLoading) {
        message = 'Please wait for the Organization Id Field validation to complete';
      }
      this.props.alertErrors(message);
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.onCancel();
  };

  isFormValid = async () => {
    const job = this.getJob();
    const result = validators.validateRecurringDonationMigrationJob(job);

    if (result.error) {
      this.setState({
        tokenFileError: validators.getValidationStateFromJoiError(result.error, 'tokenFile'),
        userFileError: validators.getValidationStateFromJoiError(result.error, 'donorFile'),
        labelError: validators.getValidationStateFromJoiError(result.error, 'label'),
        organizationIdError: validators.getValidationStateFromJoiError(result.error, 'organizationId'),
      });
      return false;
    } else {
      this.setState({
        tokenFileError: 'success',
        userFileError: 'success',
        labelError: 'success',
        organizationIdError: 'success',
      });
      return true;
    }
  };

  getJob() {
    const job = (({ id, createdAt, organizationId, label, donorFile, tokenFile, isDryRun }) => ({
      id,
      createdAt,
      organizationId,
      label,
      donorFile,
      tokenFile,
      isDryRun,
    }))(this.state);

    const submissionType = this.state.submissionType.value;
    job.submissionType = submissionType;

    return job;
  }

  handleCheckbox = (e) => {
    const isDryRun = e.target.checked;
    this.setState({ isDryRun });
  };

  handleFileChange = (e) => {
    const { files, name } = e.target;
    if (!files || !files[0]) {
      return;
    }
    this.setState({ [name]: files[0] });
  };

  handleTypeChange = (submissionType) => {
    this.setState({ submissionType });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setOrganizationId = (id) => this.setState({ organizationId: id });

  setOrganizationIdLoading = (isLoading) => this.setState({ organizationIdLoading: isLoading });

  render() {
    const { label, submissionType, isDryRun, organizationIdError, submitDisabled } = this.state;
    // const { organizationId, label, submissionType, isDryRun, donorFile } = this.state;

    const submissionNotStripeToStripe =
      this.state.submissionType.value !== constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.STRIPE_TO_STRIPE;
    const submissionIsTokenEx =
      this.state.submissionType.value === constants.RECURRING_DONATION_MIGRATION_JOB_SUBMISSION_TYPES.TOKENEX;
    return (
      <div className="form-wrapper">
        <Form noValidate validated="false">
          <OrganizationIdInput
            organizationIdError={organizationIdError}
            setOrganizationId={this.setOrganizationId}
            setOrganizationIdLoading={this.setOrganizationIdLoading}
          />
          <FormGroup controlId="label" validationState={this.state.labelError}>
            <ControlLabel>Job Label</ControlLabel>
            <FormControl
              required
              name="label"
              onChange={this.handleInputChange}
              placeholder="Job Label"
              type="text"
              value={label}
            />
            <HelpBlock>A label is required to identify this job, e.g. "Eastern US Donors"</HelpBlock>
            <FormControl.Feedback />
          </FormGroup>
          <FormControl name="submissionType" componentClass="react-select">
            <ControlLabel>Submission Type</ControlLabel>
            <Select
              name="submissionType"
              options={constants.RECURRING_DONATION_MIGRATION_OPTIONS}
              value={submissionType}
              onChange={this.handleTypeChange}
            />
            <FormControl.Feedback />
            <HelpBlock>
              Select the type of Migration you’re running. This is dependent on the Organization’s payment processor
            </HelpBlock>
          </FormControl>
          <FormGroup className="user-file" validationState={this.state.userFileError}>
            <ControlLabel>Donor File (CSV)</ControlLabel>
            <input name="donorFile" type="file" required accept=".csv" onChange={this.handleFileChange} />
            <FormControl.Feedback />
            <HelpBlock>
              A CSV is required for the Donor File.
              <a href="/recurring-donations/stripe-donor-file-template.csv" className="template-file" download>
                Link to Stripe template Donor File.
              </a>
              <a href="/recurring-donations/tokenex-donor-file-template.csv" className="template-file" download>
                Link to TokenEx template Donor File.
              </a>
            </HelpBlock>
          </FormGroup>
          {submissionNotStripeToStripe && (
            <FormGroup validationState={this.state.tokenFileError}>
              <ControlLabel>Token File {submissionIsTokenEx ? 'CSV' : 'JSON'}</ControlLabel>
              <input
                name="tokenFile"
                type="file"
                required
                accept={submissionIsTokenEx ? '.csv' : 'application/json'}
                onChange={this.handleFileChange}
              />
              <FormControl.Feedback />
              {submissionIsTokenEx ? (
                <HelpBlock>
                  A CSV file is required for the first TokenEx submission if the Donor File does not contain tokens.
                  <a href="/recurring-donations/tokenex-token-template.csv" className="template-file" download>
                    Link to template Token File.
                  </a>
                </HelpBlock>
              ) : (
                <HelpBlock>
                  A JSON file is required for the first Stripe submission if the Donor File does not contain tokens.
                  <a href="/recurring-donations/stripe-token-template.json" className="template-file" download>
                    Link to template Token File.
                  </a>
                </HelpBlock>
              )}
            </FormGroup>
          )}
          <div className="submit-button">
            <ClassyButton
              className="recurring-donations-migration__submit-button"
              title="Submit"
              disabled={submitDisabled}
              onClick={this.handleSubmit}
            />
            <ClassyButton
              className="recurring-donations-migration__submit-button"
              title="Cancel"
              disabled={submitDisabled}
              onClick={this.handleCancel}
            />
            <Checkbox checked={isDryRun} onChange={this.handleCheckbox}>
              Dry Run
            </Checkbox>
            <HelpBlock className="dry-run-help"> (When enabled, runs the Validation Job)</HelpBlock>
          </div>
        </Form>
      </div>
    );
  }
}

RecurringDonationMigrationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RecurringDonationMigrationForm;
