import { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import TransactionActions from '../../Redux/Transaction.redux';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';
import DateInput from '../DateInput/DateInput';
import Throbber from '../Throbber/Throbber';
import './AuthorizationCodeReport.scss';

export class AuthorizationCodeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'week'),
      endDate: moment(),
      showAlert: false,
      alertMessage: '',
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onSubmitReportRequest = this.onSubmitReportRequest.bind(this);
    this.renderThrobber = this.renderThrobber.bind(this);
    this.renderTransactionReport = this.renderTransactionReport.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  componentDidMount() {
    const { clearTransactions } = this.props;

    clearTransactions();
  }

  componentWillUnmount() {
    const { clearTransactions } = this.props;

    clearTransactions();
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  filterReportData = () => {
    const { transactions } = this.props;
    const data = transactions.length > 0 ? transactions : [];
    let filteredData = [];
    if (data) {
      filteredData = data.map((transaction) => ({
        transactionId: transaction.id,
        supporterId: transaction.supporter ? transaction.supporter.id : null,
        memberId: transaction.memberId,
        dateDonated: transaction.dateDonated
          ? moment(transaction.dateDonated).format(constants.DATES.REPORT_EXPORT_CONTENT_DATE_FORMAT)
          : null,
        billingFirstName: transaction.billingFirstName,
        middleName: transaction.middleName,
        billingLastName: transaction.billingLastName,
        companyName: transaction.companyName,
        email: transaction.memberEmailAddress,
        billingAddress: transaction.billingAddress,
        billingCity: transaction.billingCity,
        billingState: transaction.billingState,
        billingZip: transaction.billingPostalCode,
        billingCountry: transaction.billingCountry,
        donationGrossAmount: transaction.donationGrossAmount,
        ticketCount: transaction.ticketCount,
        promoCode: transaction.promo_code_code,
        externalId: transaction.externalId || 'Not Provided',
        designationName: transaction.designationName,
        cardType: transaction.cardType,
        authorizationCode: transaction.authorizationCode,
        cardLastFour: transaction.cardLastFour,
        campaignId: transaction.campaignId,
        campaignName: transaction.campaignName,
        fundraiserPageId: transaction.fundraiserPageId,
        pageTitle: transaction.fundraiserPageTitle,
        status: transaction.status,
        frequency:
          typeof transaction.frequency === 'string' ? transaction.frequency.toUpperCase() : transaction.frequency,
        memberName: transaction.memberName,
        memberPhone: transaction.memberPhone,
        createdAt: transaction.createdAt
          ? moment(transaction.createdAt).format(constants.DATES.REPORT_EXPORT_CONTENT_DATE_FORMAT)
          : null,
        supporterTextOptIn: transaction.supporterTextOptIn,
        comment: transaction.comment,
      }));
    }
    return filteredData;
  };

  onDateChange(date, state) {
    this.setState({ [state]: date });
  }

  onSubmitReportRequest() {
    const { endDate, startDate } = this.state;

    const formattedEndDate = endDate.endOf('day').format(constants.DATES.TRANSACTION_SEARCH_DATE_FORMAT);
    const formattedStartDate = startDate.startOf('day').format(constants.DATES.TRANSACTION_SEARCH_DATE_FORMAT);

    this.props.clearTransactions();
    this.props.setLoadingTransactions();
    this.transactionSearchRequest(formattedEndDate, formattedStartDate);
  }

  renderThrobber() {
    return <Throbber loading={this.props.loadingTransactions} />;
  }

  renderTransactionReport() {
    const { selectedOrganization } = this.props;
    const data = this.filterReportData();

    const transactionReportColumns = [
      {
        Header: 'Index',
        Cell: (row) => <div>{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
        width: 75,
      },
      {
        Header: 'Transaction Id',
        accessor: 'transactionId',
      },
      {
        Header: 'Supporter Id',
        accessor: 'supporterId',
      },
      {
        Header: 'Member Id',
        accessor: 'memberId',
      },
      {
        Header: 'Date Donated',
        accessor: 'dateDonated',
      },
      {
        Header: 'Billing First Name',
        accessor: 'billingFirstName',
      },
      {
        Header: 'Middle Name',
        accessor: 'middleName',
      },
      {
        Header: 'Billing Last Name',
        accessor: 'billingLastName',
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 200,
      },
      {
        Header: 'Billing Address',
        accessor: 'billingAddress',
      },
      {
        Header: 'Billing City',
        accessor: 'billingCity',
      },
      {
        Header: 'Billing State',
        accessor: 'billingState',
      },
      {
        Header: 'Billing Zip',
        accessor: 'billingZip',
      },
      {
        Header: 'Billing Country',
        accessor: 'billingCountry',
      },
      {
        Header: 'Donation Gross Amount',
        accessor: 'donationGrossAmount',
        Cell: (row) => <div>{row.value ? `$${row.value}` : null}</div>,
      },
      {
        Header: 'Ticket Count',
        accessor: 'ticketCount',
      },
      {
        Header: 'Promo Code',
        accessor: 'promoCode',
      },
      {
        Header: 'External Id',
        accessor: 'externalId',
        Cell: (row) => <div>{row.value || 'Not Provided'}</div>,
      },
      {
        Header: 'Designation Name',
        accessor: 'designationName',
      },
      {
        Header: 'Card Type',
        accessor: 'cardType',
      },
      {
        Header: 'Authorization Code',
        accessor: 'authorizationCode',
      },
      {
        Header: 'Card Last Four',
        accessor: 'cardLastFour',
      },
      {
        Header: 'Campaign Id',
        accessor: 'campaignId',
      },
      {
        Header: 'Campaign Name',
        accessor: 'campaignName',
        width: 150,
      },
      {
        Header: 'Fundraiser Page Id',
        accessor: 'fundraiserPageId',
      },
      {
        Header: 'Page Title',
        accessor: 'pageTitle',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
      },
      {
        Header: 'Member Name',
        accessor: 'memberName',
      },
      {
        Header: 'Member Phone',
        accessor: 'memberPhone',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
      {
        Header: 'Text Opt-In',
        accessor: 'supporterTextOptIn',
        Cell: (row) => <div id="textOptIn">{typeof row.value === 'boolean' ? row.value.toString() : null}</div>,
      },
      {
        Header: 'Comment',
        accessor: 'comment',
      },
    ];

    const exportOrganizationName = selectedOrganization
      ? constants.getChunkedOrganizationName(selectedOrganization.name)
      : '';

    return (
      <div>
        {data ? (
          <Prompt when={!!data.length} message="Are you sure you want to leave? Your results will not be retained." />
        ) : null}
        {this.renderThrobber()}
        <ClassyTable
          data={data}
          exportFilename={`${exportOrganizationName}_Donations`}
          formattedExportData={data}
          columns={transactionReportColumns}
        />
      </div>
    );
  }

  async transactionSearchRequest(endDate, startDate) {
    const organizationId = this.props.selectedOrganization.id;
    try {
      const transactionSearchResponse = await api.transactionSearchRequest({
        endDate,
        organizationId,
        startDate,
      });
      if (transactionSearchResponse.success) {
        const transactions = transactionSearchResponse.data;
        if (transactions && transactions instanceof Array) {
          if (!transactions.length) {
            this.setAlert(
              "We couldn't find any transactions for the specified date range. Please try another date range.",
            );
          }
          this.props.transactionSearchSuccess(transactions);
        } else {
          this.props.transactionSearchFailure(transactionSearchResponse);
          this.setAlert(
            "We couldn't find any transactions associated with this organization. Please contact your system administrator.",
          );
        }
      }
    } catch (error) {
      let errorMessage = 'Authorization code report';
      if (error.errors && error.errors.length) {
        errorMessage = error.errors;
      }
      this.setAlert(`Error: ${errorMessage}\nThere was an issue processing your request. Please try again.`);
      this.props.transactionSearchFailure(error);
    }
  }

  render() {
    const { endDate, startDate } = this.state;

    return (
      <div id="authorizationCodeReport">
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <div>
          <h2 className="title-text">Authorization Code Report</h2>
        </div>

        <div className="date-range__header">Select a date range for the report</div>
        <div className="date-range">
          <DateInput
            selected={startDate}
            onChange={(date) => this.onDateChange(date, 'startDate')}
            placeholder="Start Date"
            minDate={moment().subtract(process.env.REACT_APP_AUTHORIZATION_CODE_MAX_DAYS_BACK_FOR_START_DATE, 'days')}
            maxDate={endDate}
          />

          <i className="fa fa-arrow-right margin-horizontal-large" />

          <DateInput
            selected={endDate}
            onChange={(date) => this.onDateChange(date, 'endDate')}
            minDate={startDate}
            placeholder="End Date"
          />

          <ClassyButton
            className="margin-horizontal-large"
            title="Submit"
            disabled={!startDate || !endDate || this.props.loadingTransactions}
            onClick={this.onSubmitReportRequest}
          />
        </div>

        {this.renderTransactionReport()}
      </div>
    );
  }
}

AuthorizationCodeReport.propTypes = {
  clearTransactions: PropTypes.func,
  loadingTransactions: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  setLoadingTransactions: PropTypes.func.isRequired,
  transactionSearchFailure: PropTypes.func.isRequired,
  transactionSearchSuccess: PropTypes.func.isRequired,
  transactions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { loadingTransactions, transactions } = state.transactions;
  const { selectedOrganization } = state.login;
  return {
    loadingTransactions,
    selectedOrganization,
    transactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { clearTransactions, setLoadingTransactions, transactionSearchFailure, transactionSearchSuccess } =
    TransactionActions;

  return {
    clearTransactions: () => dispatch(clearTransactions()),
    setLoadingTransactions: () => dispatch(setLoadingTransactions()),
    transactionSearchFailure: (error) => dispatch(transactionSearchFailure(error)),
    transactionSearchSuccess: (transactions) => dispatch(transactionSearchSuccess(transactions)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationCodeReport);
