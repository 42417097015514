import { Checkbox, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import React, { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import ClassyButton from '../ClassyButton/ClassyButton';
import { SimpleValidationResult } from '../../Helpers/validators';
import ValidationMessage, { getValidationState } from './ValidationMessage';
import { useSelector } from 'react-redux';
import Throbber from '../Throbber/Throbber';

interface AddCampaignProps {
  onSave: (addRlcCampaign: AddRlcCampaignForm) => void;
  onCancel: () => void;
  error: string | null;
  isInFlight: boolean;
}

export interface AddRlcCampaignForm {
  classyCampaignId: number;
  usesZipCode: boolean;
}

const AddCampaign = ({ onSave, onCancel, error, isInFlight }: AddCampaignProps) => {
  const [classyCampaignId, setClassyCampaignId] = useState<string>('');
  const selectedClassyOrganizationId = useSelector<any, number | undefined>(
    (state) => state.login?.selectedOrganization?.classyOrganizationId,
  );
  const [usesZipCode, setUsesZipCode] = useState<boolean>(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationError, setValidationError] = useState<SimpleValidationResult>({});

  const onInputChange: FormEventHandler<FormControl> = useCallback((event) => {
    // not sure why the callback isn't for ChangeEvent
    const e = event as unknown as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const value = e?.target?.value || '';
    setClassyCampaignId(value.replace(/\D/g, ''));
  }, []);

  const onCheckedChanged: FormEventHandler<FormControl> = useCallback((event) => {
    const e = event as unknown as ChangeEvent<HTMLInputElement>;
    const value = e?.target?.checked;
    setUsesZipCode(value);
  }, []);

  const onSaveRequested = useCallback(() => {
    if (classyCampaignId) {
      onSave({
        classyCampaignId: Number(classyCampaignId),
        usesZipCode,
      });
    } else {
      console.error('no orgid selected');
    }
  }, [usesZipCode, classyCampaignId, onSave]);
  return (
    <>
      {error && <div className="rlcDesignations__error_text">{error}</div>}
      <FormGroup
        controlId="classyCampaignId"
        validationState={getValidationState(validationError, 'classyOrganizationId')}
      >
        {/*TODO: add the production server*/}
        <ControlLabel>
          Classy{' '}
          <a
            rel="noreferrer"
            href={`https://staging.classy-test.org/admin/${selectedClassyOrganizationId}/campaigns`}
            target="_blank"
          >
            Campaign Id
          </a>
        </ControlLabel>
        {/*https://staging.classy-test.org/admin/:classyOrganizationId/campaigns*/}
        <FormControl
          name="classyCampaignId"
          type="text"
          value={classyCampaignId}
          placeholder="Enter a classy campaign id"
          onChange={onInputChange}
          style={{ width: '50%' }}
        />
        {/*<FormControl.Feedback />*/}
        <ValidationMessage field="classyCampaignId" validationError={validationError} />
      </FormGroup>
      <Checkbox
        checked={usesZipCode}
        onChange={onCheckedChanged}
        validationState={getValidationState(validationError, 'usesZipCode') || undefined}
      >
        Automatically assign designations based on zip code unless donor chooses a designation.
      </Checkbox>
      {isInFlight && (
        <div className="rlcDesignations__throbber_container">
          <Throbber loading={isInFlight} />
        </div>
      )}
      {!isInFlight && (
        <div className="submit-button text-center" style={{ marginTop: 20 }}>
          <ClassyButton title="Cancel" className={'secondary-button'} onClick={onCancel} />
          <ClassyButton title="Add" disabled={!classyCampaignId} onClick={onSaveRequested} />
        </div>
      )}
    </>
  );
};

interface AddCampaignModalProps {
  isOpen: boolean;
  isInFlight: boolean;
  onCancel: () => void;
  onSave: (addCampaign: AddRlcCampaignForm) => void;
  title: string;
  error: string | null;
}

// SEE: https://react-bootstrap-v3.netlify.app/components/modal/
const AddCampaignModal = ({ isOpen, title, ...props }: AddCampaignModalProps) => (
  <Modal show={isOpen} onHide={props.onCancel} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddCampaign {...props} />
    </Modal.Body>
  </Modal>
);

export default AddCampaignModal;
