import React, { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import ClassyTable from '../../ClassyTable/ClassyTable';
import '../style.scss';
import constants from '../../../Helpers/constants';
import ClassyButton from '../../ClassyButton/ClassyButton';
import history from '../../../Router/history';
import api from '../../../Services/Api';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import utilities from '../../../Helpers/utilities';

const Row = (row) => <div>{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>;

const EditRow = (row) => (
  <button
    className="btn-link"
    onClick={() =>
      history.push({
        pathname: `/virtual-terminal/campaign-location/${row.row.id}/edit`,
      })
    }
  >
    Edit
  </button>
);

const CampaignLocation = ({ selectedOrgId }) => {
  const [alert, setAlert] = useState({ alertMessage: '', showAlert: false });
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const filterLocationData = (data) =>
    data.map((location) => ({
      ...location,
      customLocation:
        (get(location, 'Location.stateCode') &&
          `${get(location, 'Location.stateCode')}, ${get(location, 'Location.cityName')}`) ||
        get(location, 'Location.customLocation'),
    }));

  const fetchLocations = useCallback(async () => {
    let response;
    setLoading(true);
    try {
      const locationResponse = await api.getCampaignLocations(selectedOrgId);
      if (locationResponse.success) {
        setLocations(locationResponse.data);
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    } finally {
      setLoading(false);
    }

    if (response) {
      setAlert({ alertMessage: response.data || response, showAlert: true });
    }
    return response;
  }, [selectedOrgId]);

  const deleteLocation = async (locationId) => {
    let response;
    const confirmDelete = () => typeof window !== 'undefined' && window.confirm('Are you sure you want to delete?');

    if (confirmDelete()) {
      setLoading(true);
      try {
        const locationResponse = await api.deleteCampaignLocation({
          organizationId: selectedOrgId,
          campaignLocationId: locationId,
        });
        if (locationResponse.success) {
          fetchLocations();
        } else {
          response =
            'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
        }
      } catch (exception) {
        response = exception.errors;
      } finally {
        setLoading(false);
      }

      if (response) {
        setAlert({ alertMessage: response.data || response, showAlert: true });
      }
      return response;
    }
    return null;
  };

  const DeleteRow = (row) => (
    <button className="btn-link text-danger" onClick={async () => deleteLocation(row.row.id)}>
      Delete
    </button>
  );

  const columns = [
    {
      width: 100,
      Cell: DeleteRow,
    },
    {
      width: 50,
      Cell: EditRow,
    },
    {
      Header: 'Row #',
      Cell: Row,
      width: 90,
      resizable: false,
    },
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: 'CampaignId',
      accessor: 'campaignId',
      filterable: true,
    },
    {
      Header: 'Campaign Name',
      accessor: 'name',
      filterable: true,
      filterMethod: utilities.filterStringHelper,
    },
    {
      Header: 'Internal Campaign Name',
      accessor: 'internalName',
      filterable: true,
      filterMethod: utilities.filterStringHelper,
    },
    {
      Header: 'Location',
      accessor: 'customLocation',
      filterable: true,
      filterMethod: utilities.filterStringHelper,
    },
  ];

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  return (
    <div>
      <h2 className="title-text">Campaign Locations</h2>

      <ClassyAlert
        show={alert.showAlert}
        alertMessage={alert.alertMessage}
        onHide={() => setAlert({ alertMessage: '', showAlert: false })}
      />

      <ClassyTable
        showWhenEmpty
        data={filterLocationData(locations)}
        HeaderLeftComponent={
          <LinkContainer to={constants.ROUTES.VIRTUAL_TERMINAL_CAMPAIGN_LOCATION_CTEATE}>
            <ClassyButton title="Create Campaign Location" className="margin-vertical-large" />
          </LinkContainer>
        }
        loading={loading}
        columns={columns || []}
        defaultPageSize={15}
        pageSizeOptions={[15, 25, 50, 100]}
      />
    </div>
  );
};

CampaignLocation.propTypes = {
  selectedOrgId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    selectedOrganization: { id: selectedOrgId },
  } = state.login;

  return {
    selectedOrgId,
  };
};

export default connect(mapStateToProps)(CampaignLocation);
