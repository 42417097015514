import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import Badge from 'react-bootstrap/lib/Badge';
import { connect } from 'react-redux';

import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';
import api from '../../Services/Api';

const BadgeCell = ({ value, isValid = false }) => (
  <Badge className={isValid ? 'total-success' : 'total-error'} title={`${value} ${isValid ? 'valid' : 'invalid'} rows`}>
    {value}
  </Badge>
);

const TableView = ({ jobs, onNewJob, accessToken }) => {
  const handleNewJob = useCallback(() => onNewJob?.(), [onNewJob]);

  const shouldRenderDownload = useCallback((row) => {
    const invalidRowCount = row.original.invalidRowCount ? parseInt(row.original.invalidRowCount) : 0;
    const validRowCount = row.original.validRowCount ? parseInt(row.original.validRowCount) : 0;

    return invalidRowCount + validRowCount === row.original.rowCount;
  }, []);

  const handleDownloadJobResult = useCallback(
    (jobId) => {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = api.downloadRecurringPlanFileUrl(jobId, accessToken);
      link.click();
      document.body.removeChild(link);
    },
    [accessToken],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Org ID',
        accessor: 'organizationId',
        width: 100,
        Cell: (row) => row.value,
      },
      {
        Header: 'Job',
        width: 150,
        accessor: 'jobLabel',
        Cell: (row) => (
          <div className="job-label" title={row.value}>
            {row.value}
          </div>
        ),
      },
      {
        Header: 'Date',
        width: 200,
        accessor: 'createdAt',
        Cell: (row) => (
          <div title={moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}>
            {row.value ? moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss') : null}
          </div>
        ),
      },
      {
        Header: 'Valid',
        width: 112,
        Cell: (row) => <BadgeCell value={row.original.validRowCount} isValid />,
      },
      {
        Header: 'Invalid',
        width: 80,
        Cell: (row) => <BadgeCell rowOriginal={row.original.invalidRowCount} />,
      },
      {
        Header: 'Action',
        Cell: (row) => (
          <div style={{ display: 'flex' }}>
            {shouldRenderDownload(row) ? (
              <ClassyButton
                className="secondary-button"
                style={{ fontWeight: 'bold' }}
                title={'Download \u2913'}
                onClick={() => handleDownloadJobResult(row.original?.id)}
              />
            ) : (
              <div className="processing-job" title="Please wait until the job has finished to proceed">
                Processing Job...
              </div>
            )}
          </div>
        ),
      },
    ],
    [shouldRenderDownload, handleDownloadJobResult],
  );

  return (
    <>
      <div className="cancel-recurring-gifts__table-view">
        {!jobs?.length && <span className="cancel-recurring-gifts__message">No jobs yet.</span>}
        {!!jobs?.length && (
          <ClassyTable
            showWhenEmpty
            data={jobs}
            columns={columns}
            exportFilename="CancelRecurringGifts"
            defaultPageSize={10}
            pageSizeOptions={[10, 25, 50, 100]}
            resizable={false}
          />
        )}
      </div>
      <div className="flexRow">
        <ClassyButton title="New Job" onClick={handleNewJob} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const accessToken = state.login.accessToken;

  return {
    accessToken,
  };
};

export default connect(mapStateToProps)(TableView);
