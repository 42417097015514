import React, { Component } from 'react';
import './OrganizationIdInput.scss';
import api from '../../Services/Api';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

class OrganizationIdInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationId: '',
      organizationName: '',
      organizationNameError: '',
      organizationIdError: null,
      organizationInputDebounce: null,
      organizationInputLoading: false,
      organizationInputFocus: false,
      organizationRequestTick: 0,
    };
  }

  handleOrganizationChange = (event) => {
    const value = event.target.value.trim();

    if (this.state.organizationInputDebounce) {
      clearTimeout(this.state.organizationInputDebounce);
    }

    const debounce = setTimeout(() => this.searchOrganizationById(value), 500);

    this.setState({
      organizationId: value,
      organizationInputDebounce: debounce,
      organizationName: '',
      organizationNameError: '',
      organizationInputLoading: false,
    });
    this.props.setOrganizationIdLoading(false);
    this.props.setOrganizationId(null);
  };

  handleOrganizationFocus = () => {
    const { organizationId, organizationName } = this.state;
    if (organizationId && organizationName) {
      const suffix = ` - ${organizationName}`;
      const value = organizationId.replace(suffix, '');

      this.setState({ organizationId: value });
    }
    this.setState({ organizationInputFocus: true });
  };

  handleOrganizationBlur = () => {
    const { organizationId, organizationName } = this.state;
    if (organizationId && organizationName) {
      const suffix = ` - ${organizationName}`;
      const prefix = organizationId.replace(suffix, '');

      this.setState({ organizationId: `${prefix}${suffix}` });
    }

    this.setState({ organizationInputFocus: false });
  };

  searchOrganizationById = (id) => {
    if (!id.trim()) {
      return;
    }
    if (isNaN(id)) {
      this.setState({ organizationNameError: 'Invalid Organization Id' });
      return;
    }

    const { organizationRequestTick } = this.state;
    const currentRequestTick = organizationRequestTick + 1;

    this.props.setOrganizationIdLoading(true);
    this.setState({
      organizationRequestTick: currentRequestTick,
      organizationInputLoading: true,
    });

    api
      .searchOrganizationById({ organizationId: id })
      .then((resp) => {
        if (this.state.organizationRequestTick !== currentRequestTick || !resp || !resp.data) {
          return;
        }

        let organizationName = '';
        let organizationNameError = '';

        if (resp.data.status === 'active') {
          this.props.setOrganizationId(id);
          organizationName = resp.data.name || '';
        } else {
          organizationNameError = `Organization ID ${id} is in an invalid status, please select an active Organization`;
        }

        this.setOrganizationState({
          organizationName: organizationName,
          organizationNameError: organizationNameError,
        });
      })
      .catch((err) => {
        if (this.state.organizationRequestTick !== currentRequestTick) {
          return;
        }

        let organizationNameError = `Could not retrieve data for Organization ID ${id}, please try again`;
        if (err.errors && err.errors[0] && err.errors[0].includes('not found')) {
          organizationNameError = `Organization ID ${id} was not found, please select an active Organization`;
        }

        this.setOrganizationState({
          organizationName: '',
          organizationNameError: organizationNameError,
        });
      });
  };

  setOrganizationState(data) {
    const { organizationName, organizationNameError } = data;

    this.props.setOrganizationIdLoading(false);
    this.setState({
      organizationName: organizationName,
      organizationNameError: organizationNameError,
      organizationInputLoading: false,
    });

    const { organizationInputFocus } = this.state;
    if (!organizationInputFocus) {
      this.handleOrganizationBlur();
    }
  }

  render() {
    const { organizationId, organizationName, organizationNameError, organizationInputLoading } = this.state;

    let validationState = this.props.organizationIdError;
    if (organizationInputLoading) {
      validationState = null;
    }
    if (!!organizationName) {
      validationState = 'success';
    }

    return (
      <FormGroup controlId="organizationId" validationState={validationState} className="organizationId">
        <ControlLabel>Organization Id</ControlLabel>
        <FormControl
          required
          name="organizationId"
          className={`${!!organizationName ? 'valid-org-id' : ''}`}
          onChange={this.handleOrganizationChange}
          onFocus={this.handleOrganizationFocus}
          onBlur={this.handleOrganizationBlur}
          placeholder="Organization ID"
          type="text"
          value={organizationId}
        />

        {!!organizationNameError && <div className="invalid-message">{organizationNameError}</div>}
        {organizationInputLoading && <div className="tiny-loader"></div>}

        <HelpBlock>
          The Organization Id is required and must be a number. The Id must belong to an active and valid Organization.
        </HelpBlock>
        <FormControl.Feedback />
      </FormGroup>
    );
  }
}

OrganizationIdInput.propTypes = {
  organizationIdError: PropTypes.string,
  setOrganizationId: PropTypes.func,
  setOrganizationIdLoading: PropTypes.func,
};

export default OrganizationIdInput;
