import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, ControlLabel, FormControl, FormGroup, Row } from 'react-bootstrap';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyButton from '../../ClassyButton/ClassyButton';
import RecurringDonationCard from './RecurringDonationCard';
import PaymentProfileCard from './PaymentProfileCard';
import validators from '../../../Helpers/validators';
import utilities from '../../../Helpers/utilities';
import history from '../../../Router/history';

export class EditDonor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      country: '',
      donationStatusId: null,
      donorOriginal: {},
      email: '',
      firstName: '',
      id: null,
      lastName: '',
      recurringDonations: [],
      paymentFrequencyId: null,
      selectedRecurringDonation: {},
      state: '',
      showAlert: false,
      alertMessage: '',
    };
    this.handleFormInputChange = this.handleFormInputChange.bind(this);
    this.handleRecurringDonationInputChange = this.handleRecurringDonationInputChange.bind(this);
    this.handleRecurringDonationSelectChange = this.handleRecurringDonationSelectChange.bind(this);
    this.handleSubmitEditDonor = this.handleSubmitEditDonor.bind(this);
    this.handleSubmitEditRecurringDonation = this.handleSubmitEditRecurringDonation.bind(this);
    this.renderRecurringDonations = this.renderRecurringDonations.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.onClickIsEnabled = this.onClickIsEnabled.bind(this);
  }

  async componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.donorId) {
      const { donorId } = this.props.match.params;
      await this.getDonorById(donorId);
      this.getRecurringDonationsByDonorId(donorId);
      this.getPaymentProfilesByDonorId(donorId);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedOrgId, location } = this.props;
    const showRecurringProfile = location.state && location.state.showRecurringProfile;

    if (prevProps.selectedOrgId !== selectedOrgId) {
      if (showRecurringProfile) {
        history.push({ pathname: '/admin/recurring-profiles' });
      } else {
        history.push({ pathname: '/admin/donors' });
      }
    }
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  async getDonorById(donorId) {
    let donor;
    try {
      const getDonorByIdResponse = await api.getDonorById({ donorId });
      if (getDonorByIdResponse.success) {
        donor = getDonorByIdResponse.data;
        this.setState({
          address: donor.address || '',
          address2: donor.address2 || '',
          city: donor.city || '',
          country: donor.country || '',
          donorOriginal: donor,
          email: donor.email || '',
          firstName: donor.firstName || '',
          id: donor.id,
          lastName: donor.lastName || '',
          state: donor.state || '',
          zip: donor.zip || '',
        });
      }
    } catch (exception) {
      this.setAlert(exception.errors);
    }
    return donor;
  }

  getPaymentProfilesByDonorId = async (donorId) => {
    let message = null;
    let paymentProfiles;
    try {
      const getPaymentProfilesByDonorIdResponse = await api.getPaymentProfilesByDonorId({ donorId });
      if (getPaymentProfilesByDonorIdResponse.success) {
        paymentProfiles = getPaymentProfilesByDonorIdResponse.data;
        this.setState({ paymentProfiles });
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
    }

    return paymentProfiles;
  };

  async getRecurringDonationsByDonorId(donorId) {
    let message = null;
    let recurringDonations;
    try {
      const getRecurringDonationsByDonorIdResponse = await api.getRecurringDonationsByDonorId({ donorId });
      if (getRecurringDonationsByDonorIdResponse.success) {
        recurringDonations = getRecurringDonationsByDonorIdResponse.data;
        this.setState({ recurringDonations });
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
    }

    return recurringDonations;
  }

  handleFormInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleRecurringDonationInputChange(event) {
    const donation = { ...this.state.selectedRecurringDonation };
    donation[event.target.name] = event.target.value;

    this.setState({ selectedRecurringDonation: donation });
  }

  handleRecurringDonationSelectChange(selected, propName) {
    const donation = { ...this.state.selectedRecurringDonation };
    if (selected.value) {
      donation[propName] = selected.value;
    } else {
      donation[propName] = selected;
    }

    this.setState({ selectedRecurringDonation: donation });
  }

  isFormValid() {
    let response = false;
    if (this.isFormChanged()) {
      if (
        validators.getValidStateName(this.state.address) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.city) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.country) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateEmail(this.state.email) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.firstName) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.lastName) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.state) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.zip) === constants.VALIDATION_STATES.SUCCESS
      ) {
        response = true;
      }
    }
    return response;
  }

  isFormChanged() {
    let isChanged = false;
    if (
      this.state.address !== this.state.donorOriginal.address ||
      this.state.address2 !== this.state.donorOriginal.address2 ||
      this.state.city !== this.state.donorOriginal.city ||
      this.state.country !== this.state.donorOriginal.country ||
      this.state.email !== this.state.donorOriginal.email ||
      this.state.firstName !== this.state.donorOriginal.firstName ||
      this.state.lastName !== this.state.donorOriginal.lastName ||
      this.state.state !== this.state.donorOriginal.state ||
      this.state.zip !== this.state.donorOriginal.zip
    ) {
      isChanged = true;
    }
    return isChanged;
  }

  async handleSubmitEditDonor(event) {
    event.preventDefault();
    if (this.isFormValid()) {
      let response;
      const donor = {
        address: this.state.address,
        address2: this.state.address2,
        city: this.state.city,
        country: this.state.country,
        email: this.state.email,
        firstName: this.state.firstName,
        id: this.state.id,
        lastName: this.state.lastName,
        state: this.state.state,
        zip: this.state.zip,
      };
      try {
        const donorId = donor.id;
        const editDonorResponse = await api.editDonorById({ donor, donorId });
        if (editDonorResponse.success) {
          response = editDonorResponse.data;
        }
      } catch (exception) {
        response = exception.errors;
      }
      if (response) {
        this.setAlert(response);
        this.setState({ donorOriginal: donor });
      }
    }
  }

  async handleSubmitEditRecurringDonation() {
    const selectedDonation = this.state.selectedRecurringDonation;
    const donationId = Number(selectedDonation.id);

    // We want to make sure that the date selected doesn't have any time issues by setting hours,minutes,seconds, and milliseconds to zero.
    // If a value is a moment object, then it was modified. Otherwise, it simply stays as the original string that was retrieved from the API.
    // Using the _isAMomentObject property here to avoid having import moment directly into this file - every moment object has this "private" property.
    // We send ISO string with the timezone offset. The API converts it to UTC.
    const isMomentObjProp = '_isAMomentObject';

    let endsAt;
    if (selectedDonation.endsAt && selectedDonation.endsAt[isMomentObjProp]) {
      endsAt = utilities.setDateTime(selectedDonation.endsAt).format();
    } else {
      endsAt = selectedDonation.endsAt; // eslint-disable-line
    }

    let nextBillDate;
    if (selectedDonation.nextBillDate && selectedDonation.nextBillDate[isMomentObjProp]) {
      nextBillDate = utilities.setDateTime(selectedDonation.nextBillDate).format();
    } else {
      nextBillDate = selectedDonation.nextBillDate; // eslint-disable-line
    }

    const donation = {
      amount: selectedDonation.amount,
      donationStatusId: selectedDonation.donationStatusId,
      endsAt,
      id: selectedDonation.id,
      nextBillDate,
      paymentFrequencyId: selectedDonation.paymentFrequencyId,
      updatedBy: this.props.userId,
    };
    let message;
    try {
      const editRecurringDonationResponse = await api.editRecurringDonation({
        donation,
        donationId,
      });
      if (editRecurringDonationResponse.success) {
        message = editRecurringDonationResponse.data;
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
      this.setState({ isEditing: false, selectedRecurringDonation: {} });
      this.getRecurringDonationsByDonorId(this.state.id);
    }
  }

  onClickEdit(donation) {
    this.setState({ isEditing: true, selectedRecurringDonation: donation });
  }

  async onClickIsEnabled(selectedDonation) {
    const donation = {
      isEnabled: selectedDonation.isEnabled ? 0 : 1, // Toggle binary value isEnabled
      updatedBy: this.props.userId,
    };
    const donationId = selectedDonation.id;
    let message;
    try {
      const editRecurringDonationResponse = await api.editRecurringDonation({
        donation,
        donationId,
      });
      if (editRecurringDonationResponse.success) {
        message = editRecurringDonationResponse.data;
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
      this.getRecurringDonationsByDonorId(this.state.id);
    }
  }

  renderPaymentProfiles() {
    let paymentProfiles = null;
    const { match: { params: { donorId = null } = {} } = {} } = this.props;
    if (this.state.paymentProfiles) {
      paymentProfiles = this.state.paymentProfiles.map((profile, i) => (
        <PaymentProfileCard
          paymentProfile={profile}
          key={i}
          getPaymentProfilesByDonorId={this.getPaymentProfilesByDonorId}
          donorId={donorId}
        />
      ));
    }
    return paymentProfiles;
  }

  renderRecurringDonations() {
    let recurringDonations = null;
    const { selectedOrgId, featurePermissions } = this.props;
    if (this.state.recurringDonations) {
      recurringDonations = this.state.recurringDonations.map((recurringDonation, i) => {
        let recurringDonationCard = (
          <RecurringDonationCard
            key={i}
            recurringDonationObject={recurringDonation}
            FooterComponent={
              featurePermissions[selectedOrgId].recurringDonationProfileEdit ? (
                <div>
                  <ClassyButton
                    className="tertiary-button margin-horizontal-large"
                    title={recurringDonation.isEnabled ? 'Disable' : 'Enable'}
                    onClick={() => this.onClickIsEnabled(recurringDonation)}
                  />
                  <ClassyButton
                    className="tertiary-button"
                    title={'Edit'}
                    onClick={() => this.onClickEdit(recurringDonation)}
                  />
                </div>
              ) : null
            }
          />
        );

        if (this.state.isEditing && this.state.selectedRecurringDonation.id === recurringDonation.id) {
          recurringDonationCard = (
            <RecurringDonationCard
              key={i}
              isEditing={this.state.isEditing}
              onChangeInputText={this.handleRecurringDonationInputChange}
              onChangeInputSelect={this.handleRecurringDonationSelectChange}
              recurringDonationObject={this.state.selectedRecurringDonation}
              FooterComponent={
                <div>
                  <ClassyButton
                    className="tertiary-button margin-horizontal-large"
                    title={'Cancel'}
                    onClick={() => this.setState({ isEditing: false })}
                  />
                  <ClassyButton
                    className="tertiary-button"
                    title={'Save'}
                    onClick={this.handleSubmitEditRecurringDonation}
                  />
                </div>
              }
            />
          );
        }
        return recurringDonationCard;
      });
    }
    return recurringDonations;
  }

  render() {
    const { address, address2, city, country, firstName, lastName, email, state, zip } = this.state;
    const { location } = this.props;
    // eslint-disable-next-line prefer-destructuring
    const showRecurringProfile = location.state && location.state.showRecurringProfile;
    const titleText = showRecurringProfile ? 'Edit Recurring Profiles' : 'Edit Donor';
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">{titleText}</h2>
        <Row>
          {!showRecurringProfile && (
            <Col xs={12} md={6}>
              <form onSubmit={this.handleSubmit}>
                <FormGroup controlId="firstName" validationState={validators.getValidStateName(firstName)}>
                  <ControlLabel>First Name</ControlLabel>
                  <FormControl
                    name="firstName"
                    type="text"
                    value={firstName || ''}
                    placeholder="Jan"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="lastName" validationState={validators.getValidStateName(lastName)}>
                  <ControlLabel>Last Name</ControlLabel>
                  <FormControl
                    name="lastName"
                    type="text"
                    value={lastName || ''}
                    placeholder="Doe"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="email" validationState={validators.getValidStateEmail(email)}>
                  <ControlLabel>Email</ControlLabel>
                  <FormControl
                    name="email"
                    type="email"
                    value={email || ''}
                    placeholder="example@classy.org"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="address" validationState={validators.getValidStateName(address)}>
                  <ControlLabel>Address</ControlLabel>
                  <FormControl
                    name="address"
                    type="text"
                    value={address || ''}
                    placeholder="321 Maple St"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="address2" validationState={validators.getValidStateName(address2)}>
                  <ControlLabel>Address 2</ControlLabel>
                  <FormControl
                    name="address2"
                    type="text"
                    value={address2 || ''}
                    placeholder="Apt. 42"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="city" validationState={validators.getValidStateName(city)}>
                  <ControlLabel>City</ControlLabel>
                  <FormControl
                    name="city"
                    type="text"
                    value={city || ''}
                    placeholder="San Diego"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="state" validationState={validators.getValidStateName(state)}>
                  <ControlLabel>State</ControlLabel>
                  <FormControl
                    name="state"
                    type="text"
                    value={state || ''}
                    placeholder="CA"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="zip" validationState={validators.getValidStateName(zip)}>
                  <ControlLabel>Zip</ControlLabel>
                  <FormControl
                    name="zip"
                    type="text"
                    value={zip || ''}
                    placeholder="92127"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <FormGroup controlId="country" validationState={validators.getValidStateName(country)}>
                  <ControlLabel>Country</ControlLabel>
                  <FormControl
                    name="country"
                    type="text"
                    value={country || ''}
                    placeholder="US"
                    onChange={this.handleFormInputChange}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>

              <ClassyButton title="Submit" disabled={!this.isFormValid()} onClick={this.handleSubmitEditDonor} />
            </Col>
          )}
          <Col xs={12} md={6}>
            <div>
              <h3>Recurring Donations</h3>
              {this.renderRecurringDonations()}
            </div>
            <div>
              <h3>Payment Profiles</h3>
              {this.renderPaymentProfiles()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
EditDonor.propTypes = {
  featurePermissions: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  userId: PropTypes.number,
  selectedOrgId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    featurePermissions,
    user: { userId },
    selectedOrganization: { id: selectedOrgId },
  } = state.login;
  return { featurePermissions, userId, selectedOrgId };
};

export default connect(mapStateToProps)(EditDonor);
