import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './index.scss';
import App from './App';
import constants from './Helpers/constants';
import reportWebVitals from './reportWebVitals';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY_CLIENT || 'REACT_APP_BUGSNAG_API_KEY_CLIENT is not set',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_NODE_ENV || 'development',
  // to debug this locally, add 'development' to the enabledReleaseStages array
  enabledReleaseStages: [constants.ENVIRONMENTS.PRODUCTION, 'staging'],
  appVersion: process.env.REACT_APP_VERSION || 'unknown',
});

// TODO: fix this properly
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
