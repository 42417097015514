import { Modal } from 'react-bootstrap';
import React from 'react';
import {
  AddCampaignTaskLogEntry,
  AddDesignationTaskLogEntry,
  LogEntry,
  RlcCommandLog,
} from '../../Services/Rlc/RlcCommandLog';
import ReactJson, { CollapsedFieldProps } from 'react-json-view';

export interface LogDetailsProps {
  log: RlcCommandLog;
}

const shouldCollapse = ({ name }: CollapsedFieldProps) => {
  return name === 'entries';
};

const isAddDesignationTaskResult = (entry: LogEntry): entry is AddDesignationTaskLogEntry => {
  if (Object(entry)) {
    return 'designations' in entry;
  } else {
    return false;
  }
};
const isAddCampaignsTaskResult = (entry: LogEntry): entry is AddCampaignTaskLogEntry => {
  if (Object(entry)) {
    return 'campaigns' in entry;
  } else {
    return false;
  }
};

interface EntrySummary {
  childDesignationIds: Array<number>;
  campaignDesignationIds: Array<number>;
}

const getEntrySummary = (log: RlcCommandLog): EntrySummary => {
  const childDesignationIds = log.entries.flatMap((entry) => {
    let results: Array<number> = [];
    if (entry.log && Object.keys(entry.log).length > 0) {
      if (isAddDesignationTaskResult(entry.log)) {
        const ids = entry.log.designations.filter((x) => !x.success).map((x) => (x as any).taskData.childDesignationId);
        results = results.concat(ids);
      }
    }
    return results;
  });
  const campaignDesignationIds = log.entries.flatMap((entry) => {
    let results: Array<number> = [];
    if (entry.log && Object.keys(entry.log).length > 0) {
      if (isAddCampaignsTaskResult(entry.log)) {
        const ids = entry.log.campaigns.filter((x) => !x.success).map((x) => (x as any).taskData.campaignDesignationId);
        results = results.concat(ids);
      }
    }
    return results;
  });
  return {
    childDesignationIds,
    campaignDesignationIds,
  };
};

interface LogFailureSummaryProps {
  entrySummary: EntrySummary;
}

const LogFailureSummary = ({ entrySummary }: LogFailureSummaryProps) => {
  return (
    <div>
      <h4>Failures</h4>
      {entrySummary.childDesignationIds.length > 0 && (
        <>
          <h5>Designations ({entrySummary.childDesignationIds.length}):</h5>
          <div>{entrySummary.childDesignationIds.join(', ')}</div>
        </>
      )}
      {entrySummary.campaignDesignationIds.length > 0 && (
        <>
          <h5>Campaign Designations: ({entrySummary.campaignDesignationIds.length})</h5>
          <div>{entrySummary.campaignDesignationIds.join(', ')}</div>
        </>
      )}
    </div>
  );
};

const LogDetails = ({ log }: LogDetailsProps) => {
  const entrySummary = getEntrySummary(log);
  return (
    <>
      <LogFailureSummary entrySummary={entrySummary}></LogFailureSummary>
      <hr />
      <ReactJson shouldCollapse={shouldCollapse} src={log} />;
    </>
  );
};

interface LogDetailsModalProps {
  title: string;
  isOpen: boolean;
  log: RlcCommandLog;
  onCancel: () => void;
}

// SEE: https://react-bootstrap-v3.netlify.app/components/modal/
const LogDetailsModal = ({ isOpen, title, onCancel, ...props }: LogDetailsModalProps) => (
  <Modal show={isOpen} onHide={onCancel} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <LogDetails {...props} />
    </Modal.Body>
  </Modal>
);

export default LogDetailsModal;
