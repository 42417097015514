import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import papaParse from 'papaparse';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { get, keys, pickBy } from 'lodash';
import httpStatusCodes from 'http-status-codes';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import FileInput from '../FileInput/FileInput';
import ClassyTable from '../ClassyTable/ClassyTable';
import Throbber from '../Throbber/Throbber';
import ProgramDesignationActions from '../../Redux/ProgramDesignations.redux';
import LoginActions from '../../Redux/Login.redux';
import ClassyTableCell from '../ClassyTableCell/ClassyTableCell';
import ClassyTableCheckbox from '../ClassyTableCheckbox/ClassyTableCheckbox';
import './ProgramDesignations.scss';
import HelpComponent from './HelpComponent/HelpComponent';
import validators from '../../Helpers/validators';
import token from '../../Services/token';

const {
  FILE_IMPORT: { COLUMNS_MISMATCH },
  CELL_TYPES: { TEXTBOX, CHECKBOX },
  PROGRAM_DESIGNATIONS: { REQUIRED_FIELDS },
  MESSAGES: { ERROR_DEFAULT },
  FILE_FORMAT_ERROR,
} = constants;

const INITIAL_STATE = {
  errors: {},
  showAlert: false,
  alertMessage: '',
  isParsingTemplate: false,
  isOrgDataLoading: false,
};

class ProgramDesignations extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;

    this.columns = [
      {
        Cell: (row) =>
          typeof row.index !== 'undefined' ? (
            <div className="non-editable-cell" onClick={() => this.deleteTemplateRow(row.index)}>
              <i className="fa fa-minus-circle" />
            </div>
          ) : null,
        width: 50,
        resizable: false,
      },
      {
        Header: 'Index',
        Cell: (row) => (
          <div className="non-editable-cell">{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>
        ),
        width: 90,
        resizable: false,
      },
      {
        Header: 'Update',
        accessor: 'isUpdate',
        Cell: (row) => this.renderCell(row, CHECKBOX),
        width: 100,
      },
      {
        Header: 'Program Designation Id',
        accessor: 'programDesignationId',
        Cell: (row) => this.renderCell(row, TEXTBOX, true, true, !row.row.isUpdate, null, null),
        width: 150,
      },
      {
        Header: 'Program Designation Name',
        accessor: 'programDesignation',
        Cell: (row) => this.renderCell(row, TEXTBOX, true, null, null, 127, true),
        width: 150,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (row) => this.renderCell(row, TEXTBOX, null, null, null, 256),
        width: 150,
      },
      {
        Header: 'External Id',
        accessor: 'externalReferenceId',
        Cell: (row) => this.renderCell(row, TEXTBOX, null, null, null, 100),
        width: 150,
      },
      {
        Header: 'Goal',
        accessor: 'goal',
        Cell: (row) => this.renderCell(row, TEXTBOX, true, true, null, null, true),
      },
      {
        Header: 'Display Status',
        accessor: 'displayStatus',
        Cell: (row) => this.renderCell(row, CHECKBOX),
        width: 100,
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: (row) => this.renderCell(row, TEXTBOX, null, null, null, 100),
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: (row) => this.renderCell(row, TEXTBOX, null, null, null, 2),
      },
      {
        Header: 'Zip',
        accessor: 'zip',
        Cell: (row) => this.renderCell(row, TEXTBOX, null, null, null, 10),
      },
    ];
  }

  componentDidMount() {
    const { programDesignationsTemplateData } = this.props;
    if (!programDesignationsTemplateData.length) {
      this.addProgramDesignationsTemplateRow();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectOrganization,
      selectedOrganization,
      updateProgramDesignationsTemplate,
      programDesignationsTemplateData,
    } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      if (prevProps.programDesignationsTemplateData.length && !this.confirmOrganizationChange()) {
        selectOrganization(prevProps.selectedOrganization);
        updateProgramDesignationsTemplate(prevProps.programDesignationsTemplateData);
      }
    }
    if (!programDesignationsTemplateData.length) {
      this.addProgramDesignationsTemplateRow();
    }
  }

  componentWillUnmount() {
    this.clearTable();
  }

  confirmOrganizationChange = () =>
    window.confirm('Are you sure you want to change organizations? Your work will not be saved.');

  filterProgramDesignationsData = () => {
    const data = this.props.programDesignationsTemplateData;
    let filteredData = [];
    if (data) {
      filteredData = data.map((programDesignation) => ({
        isUpdate: !!programDesignation.isUpdate,
        programDesignationId: programDesignation.programDesignationId,
        programDesignation: programDesignation.programDesignation,
        description: programDesignation.description,
        externalReferenceId: programDesignation.externalReferenceId,
        goal: programDesignation.goal,
        displayStatus: !!programDesignation.displayStatus,
        city: programDesignation.city,
        state: programDesignation.state,
        zip: programDesignation.zip,
      }));
    }
    return filteredData;
  };

  clearTable = () => {
    const { clearProgramDesignationsTemplateData } = this.props;
    this.setState({ errors: {} });
    clearProgramDesignationsTemplateData();
  };

  /**
   * Check the state of the table to determine if it's been updated and currently has data
   */
  hasData = () => {
    const { programDesignationsTemplateData } = this.props;

    let hasData = false;

    // We only need to check for modified values of each column if we have a single row, otherwise having multiple rows means the table has been touched
    if (programDesignationsTemplateData && programDesignationsTemplateData.length === 1) {
      const singleRecord = programDesignationsTemplateData[0];

      // For each column, check if the single record has a value specified
      const columnsWithData = Object.keys(singleRecord).filter((key) => singleRecord[key]);

      // If any columns have data, then the single record has data and therefore the table does
      hasData = columnsWithData.length > 0;
    } else if (programDesignationsTemplateData && programDesignationsTemplateData.length > 1) {
      hasData = true;
    }

    return hasData;
  };

  deleteTemplateRow = (rowIndex) => {
    const { programDesignationsTemplateData, updateProgramDesignationsTemplate } = this.props;
    const newData = [
      ...programDesignationsTemplateData.slice(0, rowIndex),
      ...programDesignationsTemplateData.slice(rowIndex + 1),
    ];
    const { errors } = this.state;

    let issues = {};

    Object.keys(errors).map((error) => {
      if (errors[error] === false) {
        return null;
      }
      issues = { ...issues, [error]: true };
      return issues;
    });

    if (Object.keys(issues).length && keys(pickBy(issues)).length > 0) {
      Object.keys(issues).map((error) => {
        if (error.startsWith(rowIndex)) {
          delete issues[error];
          this.setState({ errors: issues });
        }
        if (Number(error.charAt(0)) > rowIndex) {
          const err = error.replace(error.charAt(0), Number(error.charAt(0)) - 1);
          delete issues[error];
          this.setState({ errors: { ...issues, [err]: true } });
        }
        return null;
      });
    }

    updateProgramDesignationsTemplate(newData);
  };

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  renderThrobber = (message) => (
    <React.Fragment>
      <Throbber loading={true} />
      {message ? <span> {message} </span> : null}
    </React.Fragment>
  );

  addProgramDesignationsTemplateRow = () => {
    const { addProgramDesignationsTemplateRow, programDesignationsTemplateRow } = this.props;
    const row = Object.assign({}, programDesignationsTemplateRow);
    addProgramDesignationsTemplateRow(row);
  };

  onDownloadTemplate = () => {
    const { programDesignationsTemplateData, programDesignationsTemplateRow } = this.props;
    const csv = papaParse.unparse(
      programDesignationsTemplateData.length ? this.filterProgramDesignationsData() : [programDesignationsTemplateRow],
    );
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'programDesignations.csv';
    saveAs(csvBlob, csvName);
  };

  onDownloadDesignationsTemplate = () => {
    const {
      selectedOrganization: { id: organizationId },
    } = this.props;

    const accessToken = token.getToken();
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = `${process.env.REACT_APP_CLASSY_SERVICES_API_URL}organizations/${organizationId}/download-classy-org-designations?csvtoken=${accessToken}`;
    link.click();
  };

  setErrorState = (row, isValid) => {
    const { errors } = this.state;
    const key = `${row.index}-${row.column.id}`;
    errors[key] = !isValid;
    this.setState({ errors });
  };

  importProgramDesignations = async () => {
    const {
      selectedOrganization: { id: organizationId },
      programDesignationsTemplateData: programDesignations,
      toggleLoadingProgramDesignations,
    } = this.props;
    toggleLoadingProgramDesignations();
    try {
      const importDesignationsResponse = await api.importProgramDesignations({
        programDesignations,
        organizationId,
      });
      if (importDesignationsResponse.success) {
        const { data } = importDesignationsResponse;
        const { validationErrors } = data;
        if (validationErrors && validationErrors.length) {
          toggleLoadingProgramDesignations(false);
          const errorList = validationErrors.map((errorRow) => {
            const errorItems = errorRow.issues.map((errorItem) => errorItem);
            return `Row ${errorRow.index + 1}: Invalid ${errorItems}`;
          });
          this.setAlert(errorList);
        } else {
          if (typeof data === 'object') {
            this.setAlert(data.text);
            toggleLoadingProgramDesignations(false);
          }
          this.clearTable();
        }
      } else {
        this.setAlert(ERROR_DEFAULT);
      }
    } catch (error) {
      toggleLoadingProgramDesignations(false);
      if (error.statusCode === httpStatusCodes.GATEWAY_TIMEOUT) {
        this.setAlert(constants.FILE_PROCESSING_MSG);
        this.clearTable();
      } else {
        this.setAlert(error.errors[0]);
      }
    }
  };

  onChangeTableCell = (row, value, isValid) => {
    const { programDesignationsTemplateData, updateProgramDesignationsTemplate } = this.props;
    const data = [...programDesignationsTemplateData];
    data[row.index][row.column.id] = value;
    this.setErrorState(row, isValid);
    updateProgramDesignationsTemplate(data);
  };

  onCheck = (row, checked) => {
    const { programDesignationsTemplateData, updateProgramDesignationsTemplate } = this.props;
    const data = [...programDesignationsTemplateData];
    data[row.index][row.column.id] = checked;
    if (data[row.index][row.column.id] === false) {
      data[row.index].programDesignationId = '';
    }
    updateProgramDesignationsTemplate(data);
  };

  validateFileImport(data) {
    let errors = [];
    if (data && Array.isArray(data) && data.length > 0) {
      if (data.length) {
        // Grab a sample record so we can make sure the columns are correct
        const firstRecord = data[0];
        // Construct an array of the complete list of column names
        const masterColumnList = Object.keys(this.props.programDesignationsTemplateRow);

        // Construct an array of the columns in the file
        const fileColumnList = Object.keys(firstRecord);

        if (fileColumnList.length !== masterColumnList.length) {
          return { errors: [COLUMNS_MISMATCH] };
        }
        fileColumnList.forEach((column) => {
          if (!masterColumnList.includes(column)) {
            errors = [...errors, `The column "${column}" is not recognized`];
          }
        });
        if (errors && errors.length) {
          return { errors };
        }

        let errorList = [];
        data.map((designation, index) => {
          const {
            programDesignationId,
            programDesignation,
            description,
            externalReferenceId,
            city,
            state,
            zip,
            goal,
            displayStatus,
            isUpdate,
          } = designation;
          const validations = {
            programDesignationId:
              isUpdate.toLowerCase() === 'true' && !Number.isInteger(parseInt(programDesignationId, 10)),
            programDesignation: programDesignation.length > 127 || programDesignation.trim().length === 0,
            'description (longer than 256 chars)': description.length > 256,
            externalReferenceId: externalReferenceId.length > 100,
            goal: Number.isNaN(Number(goal)) || goal < 1 || goal > 999999.99,
            city: city.length > 100,
            state: state.length > 2,
            zip: zip.length > 10,
          };

          const fileFormatError = {
            displayStatus: !['true', 'false'].includes((displayStatus && displayStatus.toLowerCase()) || undefined),
            isUpdate: !['true', 'false'].includes((isUpdate && isUpdate.toLowerCase()) || undefined),
          };

          const inValidFormat = keys(pickBy(fileFormatError));

          if (inValidFormat.length > 0) {
            if (get(errors, 'errors.length') > 0) {
              errors = {
                errors: [...errors.errors, { index: index + 1, inValidFormat }],
              };
            } else {
              errors = { errors: [{ index: index + 1, inValidFormat }] };
            }
          } else {
            const issues = keys(pickBy(validations));
            if (issues.length > 0) {
              this.setState({
                errors: {
                  ...this.state.errors,
                  ...Object.assign({}, ...issues.map((issue) => ({ [`${index}-${issue}`]: true }))),
                },
              });
              errorList = [
                ...errorList,
                {
                  index: index + 1,
                  issues,
                },
              ];
            }
          }
          return null;
        });
        if (get(errors, errors.length > 0)) {
          this.setState({ errors: {} });
        } else if (errorList.length > 0) {
          errors = { ...errors, errorList };
        }
      }
    }

    return errors;
  }

  onUploadTemplate = (file) => {
    if (file) {
      const { updateProgramDesignationsTemplate } = this.props;
      this.setState({ isParsingTemplate: true });
      papaParse.parse(file, {
        dynamicTyping: false,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results && results.data) {
            const parsedTemplate = results.data;
            const validationErrors = this.validateFileImport(parsedTemplate);

            if (get(validationErrors, 'errors.length') > 0) {
              let errorList = [FILE_FORMAT_ERROR];
              if (typeof validationErrors.errors[0] === 'object') {
                errorList = errorList.concat(
                  validationErrors.errors.map((errorRow) => {
                    const errorItems = errorRow.inValidFormat.map((errorItem) => ` ${errorItem}`);
                    return `Row ${errorRow.index}: Invalid ${errorItems}`;
                  }),
                );
              } else {
                errorList = errorList.concat(validationErrors.errors.map((error) => error));
              }
              this.setAlert(errorList);
            } else {
              if (get(validationErrors, 'errorList.length') > 0) {
                const errorList = validationErrors.errorList.map((errorRow) => {
                  const errorItems = errorRow.issues.map((errorItem) => ` ${errorItem}`);
                  return `Row ${errorRow.index}: Invalid ${errorItems}`;
                });
                this.setAlert(errorList);
              }

              const templateP = parsedTemplate.map((template) => {
                const templateData = {};
                Object.keys(template).map((temp) => {
                  if (['isUpdate', 'displayStatus'].includes(temp)) {
                    templateData[temp] =
                      (!!template[temp] &&
                        ['true', 'false'].includes(template[temp].toLowerCase()) &&
                        JSON.parse(template[temp].toLowerCase())) ||
                      false;
                  } else {
                    templateData[temp] = template[temp];
                  }
                  return templateData;
                });
                return templateData;
              });
              updateProgramDesignationsTemplate(templateP);
            }
          }
          this.setState({ isParsingTemplate: false });
        },
      });
    }
  };

  onOpenFileBrowser = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const { type } = file;
    if (!validators.isValidCSVFile(type)) {
      return this.setAlert('Invalid file format, please upload .csv file.');
    }
    this.onUploadTemplate(file);
    e.target.value = null;
    return null;
  };

  renderCell = (row, cellType, isRequired, isNumeric, isDisabled, charLimit, isNonEmpty) => {
    const { programDesignationsTemplateData } = this.props;
    const value = get(programDesignationsTemplateData, [[row.index], [row.column.id]]);
    switch (cellType) {
      case TEXTBOX:
        return (
          <ClassyTableCell
            isRequired={isRequired}
            isNumeric={isNumeric}
            charLimit={charLimit}
            row={row}
            isNonEmpty={isNonEmpty}
            onChange={this.onChangeTableCell}
            isDisabled={isDisabled}
            value={value}
          />
        );
      case CHECKBOX:
        return <ClassyTableCheckbox row={row} onChange={this.onCheck} checked={value} />;
      default:
        return null;
    }
  };

  renderTemplate = () => {
    const { errors } = this.state;
    const { programDesignationsTemplateData } = this.props;
    const hasErrors = Object.keys(errors).filter((key) => errors[key]).length > 0;
    const incompleteRows = programDesignationsTemplateData.filter((row) => {
      let requiredKeys = REQUIRED_FIELDS;
      if (row.isUpdate === true) {
        requiredKeys = constants.PROGRAM_DESIGNATIONS.REQUIRED_FIELDS_PD;
      }
      return requiredKeys.filter((key) => !row[key]).length;
    });
    const isComplete = incompleteRows.length === 0;

    return (
      <div className="program-designations__table-container">
        {programDesignationsTemplateData ? (
          <Prompt
            when={!!programDesignationsTemplateData.length}
            message="Are you sure you want to leave? Your work will not be saved."
          />
        ) : null}
        {this.state.isParsingTemplate || this.props.loadingProgramDesignations ? this.renderThrobber() : null}
        <span>*Required fields are highlighted red when left blank</span>
        <ClassyTable
          className="program-designations__table"
          showWhenEmpty
          data={programDesignationsTemplateData.length > 0 ? programDesignationsTemplateData : []}
          columns={this.columns || []}
          getTdProps={() => ({
            style: { padding: 5, display: 'block', marginBottom: 5 },
          })}
          FooterLeftComponent={
            <span className="padding-large" onClick={this.addProgramDesignationsTemplateRow}>
              <i className="fa fa-plus-circle" />
              <button className="padding-medium btn-link">Add Row</button>
            </span>
          }
          clearTable={this.clearTable}
        />
        <div className="flexRow">
          <ClassyButton
            className="program-designations__submit-button"
            disabled={
              !programDesignationsTemplateData.length ||
              hasErrors ||
              !isComplete ||
              this.props.loadingProgramDesignations
            }
            title="Submit"
            onClick={this.importProgramDesignations}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Program Designations</h2>
        <div className="program-designations__table-header-button-group">
          <div className="flexRow">
            <ClassyButton
              className="secondary-button program-designations__table-header-button"
              title={this.hasData() ? 'Save for Later' : 'Download Template'}
              onClick={this.onDownloadTemplate}
            />
            <ClassyButton
              className="secondary-button program-designations__table-header-button"
              title={'Download Designations'}
              onClick={this.onDownloadDesignationsTemplate}
            />
            <FileInput
              className="program-designations__table-header-button"
              hideInput
              inputId="uploadedTemplate"
              buttonLabel="Upload Template"
              onOpenFileBrowser={this.onOpenFileBrowser}
              accept=".csv"
            />
          </div>
          <HelpComponent />
        </div>

        {!this.state.isOrgDataLoading ? this.renderTemplate() : this.renderThrobber()}
      </div>
    );
  }
}

ProgramDesignations.propTypes = {
  loadingProgramDesignations: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  addProgramDesignationsTemplateRow: PropTypes.func.isRequired,
  programDesignationsTemplateRow: PropTypes.object.isRequired,
  clearProgramDesignationsTemplateData: PropTypes.func.isRequired,
  programDesignationsTemplateData: PropTypes.array.isRequired,
  updateProgramDesignationsTemplate: PropTypes.func.isRequired,
  selectOrganization: PropTypes.func.isRequired,
  toggleLoadingProgramDesignations: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const {
    loadingProgramDesignations,
    programDesignations,
    programDesignationsTemplateData,
    programDesignationsTemplateRow,
  } = state.programDesignations;

  return {
    loadingProgramDesignations,
    programDesignations,
    selectedOrganization,
    programDesignationsTemplateData,
    programDesignationsTemplateRow,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    addProgramDesignationsTemplateRow,
    clearProgramDesignationsTemplateData,
    updateProgramDesignationsTemplate,
    toggleLoadingProgramDesignations,
  } = ProgramDesignationActions;
  const { selectOrganization } = LoginActions;

  return {
    addProgramDesignationsTemplateRow: (row) => dispatch(addProgramDesignationsTemplateRow(row)),
    clearProgramDesignationsTemplateData: () => dispatch(clearProgramDesignationsTemplateData()),
    selectOrganization: (organization) => dispatch(selectOrganization(organization)),
    updateProgramDesignationsTemplate: (data) => dispatch(updateProgramDesignationsTemplate(data)),
    toggleLoadingProgramDesignations: (isLoading) => dispatch(toggleLoadingProgramDesignations(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDesignations);
