import jwt from 'jsonwebtoken';

const TOKEN_KEY = 'x-auth';

const getToken = () => localStorage.getItem(TOKEN_KEY);

const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const decodeToken = (token) => jwt.decode(token);

const isTokenExpired = () => {
  const token = getToken();
  if (!token) {
    return true;
  }

  const decodedInfo = jwt.decode(token);
  if (!decodedInfo) {
    return true;
  }

  const now = Date.now().valueOf() / 1000;
  return decodedInfo.exp < now;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  TOKEN_KEY,
  getToken,
  setToken,
  removeToken,
  decodeToken,
  isTokenExpired,
};
