import api from '../../Services/Api';
import useSWR, { useSWRConfig } from 'swr';
import Badge from 'react-bootstrap/lib/Badge';
import { APIResponse } from '../../Typings/Services/Api';
import './Queues.scss';
import constants from '../../Helpers/constants';
import Queue from './Queue';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const fetcher = (url: string) => api.api.get(url).then((res) => res);

interface RlcQueueStatus {
  name: string;
  isPaused: boolean;
  active: number;
  completed: number;
  delayed: number;
  failed: number;
  waiting: number;
  waitingChildren: number;
}

interface StatusChange {
  name: string;
  isPaused: boolean;
}

interface QueueStatusProps {
  queue: RlcQueueStatus;
  onStatusChanged: (config: StatusChange) => void;
}

const QueueStatusBadge = ({ isPaused }: { isPaused: boolean }) => (
  <Badge className={isPaused ? 'error' : 'active'}>{isPaused ? 'Paused' : 'Active'}</Badge>
);

interface QueueStatusRowProps {
  title: string;
  queueName: string;
  status: string;
  total: number;
}

const QueueStatusRow = ({ title, queueName, status, total }: QueueStatusRowProps) => (
  <>
    <div className="queue__status">
      <Link to={{ pathname: `${constants.ROUTES.RLC_QUEUES}/${queueName}/${status}` }}>{title}</Link>
    </div>
    <div className="queue__count">
      <Badge className="neutral">{total}</Badge>
    </div>
  </>
);

const QueueStatus = ({ queue, onStatusChanged }: QueueStatusProps) => {
  const onRestartClicked = async () => {
    await api.setQueueIsPaused(queue.name, false);
    onStatusChanged({ name: queue.name, isPaused: false });
  };
  const onPauseClicked = async () => {
    await api.setQueueIsPaused(queue.name, true);
    onStatusChanged({ name: queue.name, isPaused: true });
  };

  return (
    <div className="queue__container">
      <div className="queue__header">{queue.name}</div>
      <div className="queue__status">Status</div>
      <div className="queue__count">
        <QueueStatusBadge isPaused={queue.isPaused} />
      </div>
      <QueueStatusRow queueName={queue.name} status="active" title="Active" total={queue.active} />
      <QueueStatusRow queueName={queue.name} status="completed" title="Completed" total={queue.completed} />
      <QueueStatusRow queueName={queue.name} status="failed" title="Failed" total={queue.failed} />
      <QueueStatusRow queueName={queue.name} status="delayed" title="Delayed" total={queue.delayed} />
      <QueueStatusRow queueName={queue.name} status="waiting" title="Waiting" total={queue.waiting} />
      <QueueStatusRow
        queueName={queue.name}
        status="waitingChildren"
        title="Waiting Children"
        total={queue.waitingChildren}
      />
      {queue.isPaused ? (
        <div className="queue__footer">
          <Button className="btn-primary" onClick={onRestartClicked}>
            Restart Queue
          </Button>
        </div>
      ) : (
        <div className="queue__footer">
          <Button className="btn-danger" onClick={onPauseClicked}>
            Pause Queue
          </Button>
        </div>
      )}
    </div>
  );
};

const Queues = () => {
  const { data: swrData } = useSWR<APIResponse<Array<RlcQueueStatus>>>(`${api.apiUrl}rlc/queues`, fetcher as any);
  const { mutate } = useSWRConfig();
  const onStatusChanged = async () => {
    await mutate(`${api.apiUrl}rlc/queues`);
  };

  return (
    <>
      <h4>Queues</h4>
      <div className="queues">
        {swrData &&
          swrData.success &&
          swrData.data.map((queue) => <QueueStatus key={queue.name} queue={queue} onStatusChanged={onStatusChanged} />)}
      </div>
    </>
  );
};

interface QueueSelectorProps {
  match: any;
}

const QueueSelector = ({ match }: QueueSelectorProps) => {
  const queue = match?.params?.id;
  const status = match?.params?.status;
  return queue && status ? <Queue name={queue} status={status} /> : <Queues />;
};

export default QueueSelector;
