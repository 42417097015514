import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Badge from 'react-bootstrap/lib/Badge';
import { v4 as uuidv4 } from 'uuid';
import constants from '../../Helpers/constants';
import sorting from '../../Helpers/sorting';
import validators from '../../Helpers/validators';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';

const defaultSubmissionType = constants.RECURRING_DONATION_MIGRATION_OPTIONS[0].value;

const formatSubmissionType = (key) => {
  const kv = constants.RECURRING_DONATION_MIGRATION_OPTIONS.find((k) => k.value === key);
  return kv ? kv.label : key;
};

const RowTotalComponent = ({ rowOriginal }) =>
  !rowOriginal.rowsImported ? <Badge variant="secondary">Pending</Badge> : rowOriginal.rowCount;

const ValidCellComponent = ({ rowOriginal }) => {
  if (!rowOriginal.rowsImported) {
    return '-';
  }
  if (rowOriginal.rowCount - rowOriginal.validRowCount === 0) {
    return (
      <Badge className="total-success" title="Job completely Validated">
        Validated
      </Badge>
    );
  }
  return (
    <Badge className="total-success" title={`${rowOriginal.validRowCount} valid rows`}>
      {rowOriginal.validRowCount}
    </Badge>
  );
};
const InvalidCellComponent = ({ rowOriginal }) => {
  if (!rowOriginal.rowsImported) {
    return '-';
  }
  return (
    <Badge className="total-error" title={`${rowOriginal.invalidRowCount} invalid rows`}>
      {rowOriginal.invalidRowCount}
    </Badge>
  );
};

const SubmissionSuccessCellComponent = ({ rowOriginal }) => {
  if (!rowOriginal.rowsImported) {
    return '-';
  }
  if (rowOriginal.rowCount - rowOriginal.successfullySubmittedRowCount === 0) {
    return (
      <Badge className="total-success" title="Job completely Submitted">
        Submitted
      </Badge>
    );
  }
  return rowOriginal.isDryRun ? (
    'N/A'
  ) : (
    <Badge className="total-success" title={`${rowOriginal.successfullySubmittedRowCount} successfully submitted rows`}>
      {rowOriginal.successfullySubmittedRowCount}
    </Badge>
  );
};

const SubmissionUnsuccessfulCellComponent = ({ rowOriginal }) => {
  if (!rowOriginal.rowsImported) {
    return '-';
  }
  return rowOriginal.isDryRun ? (
    'N/A'
  ) : (
    <Badge
      className="total-error"
      title={`${rowOriginal.unsuccessfullySubmittedRowCount} unsuccessfully submitted rows`}
    >
      {rowOriginal.unsuccessfullySubmittedRowCount}
    </Badge>
  );
};

export class RecurringDonationMigrationView extends Component {
  customControlStyles = {
    width: '175px',
  };

  reloadJobsInterval = null;
  reloadJobsMs = 4000;

  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      jobsLoader: false,
      page: props.page || constants.PAGINATION.PAGE_DEFAULT,
      pageLoader: false,
      pageSize: props.pageSize || constants.PAGINATION.SIZE_DEFAULT,
      showAlert: false,
      sort: 'createdAt.desc',
    };
  }

  componentDidMount = () => {
    this.reloadJobsInterval = setInterval(() => this.getJobsInterval(), this.reloadJobsMs);
  };

  componentWillUnmount = () => {
    if (this.reloadJobsInterval) {
      clearInterval(this.reloadJobsInterval);
    }
  };

  getJobsInterval = async () => {
    this.setState({ jobsLoader: true });
    await this.props.getJobs(this.state.page, this.state.pageSize, this.state.sort);
    this.setState({ jobsLoader: false });
  };

  onFetchData = async (state) => {
    const { page, pageSize, sorted } = state;
    const sort = sorted.length > 0 ? sorting.reactTableSortedArrayToString(sorted) : 'createdAt.desc';
    this.setState({ page, pageSize, sort, pageLoader: true });
    await this.props.getJobs(page, pageSize, sort);
    this.setState({ pageLoader: false });
  };

  render() {
    return (
      <div className="recurring-migrations-wrapper">
        {this.state.jobsLoader && <div className="jobs-loader" />}
        {this.renderTable()}
      </div>
    );
  }

  renderTable() {
    if (!this.props.recurringDonationMigrationJobs || this.props.loadingRecurringDonationMigrationJobs) {
      return <span>Loading...</span>;
    }

    if (this.props.recurringDonationMigrationJobs && !this.props.recurringDonationMigrationJobs.length) {
      return <span>No jobs yet.</span>;
    }

    return (
      <ClassyTable
        className="recurring-donation-migrations__table"
        showWhenEmpty
        columns={this.columns}
        data={this.props.recurringDonationMigrationJobs}
        defaultPageSize={constants.PAGINATION.SIZE_DEFAULT}
        exportFilename={'RecurringDonationsMigration'}
        loading={this.state.pageLoader}
        manualPagination={true}
        onFetchData={this.onFetchData}
        pages={this.props.recurringDonationMigrationJobsTotalPages}
        pageSizeOptions={constants.PAGINATION.SIZE_OPTIONS}
        resizable={false}
      />
    );
  }

  createNewJob = () => {
    const newJob = {
      id: uuidv4(),
      createdAt: Date.now(),
      organizationId: '',
      label: '',
      submissionType: defaultSubmissionType,
    };
    this.setState({
      recurringDonationMigrationJobs: [newJob, ...this.state.recurringDonationMigrationJobs],
    });
  };

  onSubmit = (row, submit) => {
    const record = { ...row.original, ...{ submit } };
    this.props.onFileUpload(record);
  };

  getJoiValidationError = (key) => validators.getJoiErrorArrayForField(this.props.clientValidationErrors?.error, key);

  hasValidationBeenCompleted = (row) => {
    const invalidValidationRowCount = row.original.invalidRowCount ? parseInt(row.original.invalidRowCount) : 0;
    const validValidationRowCount = row.original.validRowCount ? parseInt(row.original.validRowCount) : 0;

    return invalidValidationRowCount + validValidationRowCount === row.original.rowCount && row.original.rowsImported;
  };

  shouldRenderDownload = (row) => {
    if (row.original.isDryRun) {
      return this.hasValidationBeenCompleted(row);
    }

    const invalidSubmissionRowCount = row.original.unsuccessfullySubmittedRowCount
      ? parseInt(row.original.unsuccessfullySubmittedRowCount)
      : 0;
    const validSubmissionRowCount = row.original.successfullySubmittedRowCount
      ? parseInt(row.original.successfullySubmittedRowCount)
      : 0;

    return invalidSubmissionRowCount + validSubmissionRowCount === row.original.rowCount && row.original.rowsImported;
  };

  columns = [
    {
      Header: 'Org ID',
      accessor: 'organizationId',
      width: 100,
      Cell: (row) => row.value,
    },
    {
      Header: 'Job',
      width: 150,
      accessor: 'label',
      Cell: (row) => (
        <div className="job-label" title={row.value}>
          {row.value}
        </div>
      ),
    },
    {
      Header: 'Date',
      width: 200,
      accessor: 'createdAt',
      Cell: (row) => (
        <div title={moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss')}>
          {row.value ? moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss') : null}
        </div>
      ),
    },
    {
      Header: 'Type',
      accessor: 'submissionType',
      width: 125,
      Cell: (row) => <div>{formatSubmissionType(row.value)}</div>,
    },
    {
      Header: 'Donors',
      // accessor: 'validation',
      width: 94,
      Cell: (row) => <RowTotalComponent rowOriginal={row.original} />,
      getProps: () => ({ className: 'center-column-data' }),
    },
    {
      Header: 'Valid',
      width: 112,
      Cell: (row) => <ValidCellComponent rowOriginal={row.original} />,
      getProps: () => ({ className: 'center-column-data' }),
    },
    {
      Header: 'Invalid',
      width: 80,
      Cell: (row) => <InvalidCellComponent rowOriginal={row.original} />,
      getProps: () => ({ className: 'center-column-data' }),
    },
    {
      Header: 'Submitted',
      width: 112,
      Cell: (row) => <SubmissionSuccessCellComponent rowOriginal={row.original} />,
      getProps: () => ({ className: 'center-column-data' }),
    },
    {
      Header: 'Error',
      width: 80,
      Cell: (row) => <SubmissionUnsuccessfulCellComponent rowOriginal={row.original} />,
      getProps: () => ({ className: 'center-column-data' }),
    },
    {
      Header: 'Action',
      Cell: (row) => (
        <div style={{ display: 'flex' }}>
          {this.shouldRenderDownload(row) ? (
            <ClassyButton
              className="secondary-button"
              style={{ fontWeight: 'bold' }}
              title={'Donors \u2913'}
              onClick={() => this.props.onDownloadDonors(row.original?.id)}
            />
          ) : (
            <div className="processing-job" title="Please wait until the job has finished to proceed">
              {this.hasValidationBeenCompleted(row) ? 'Submitting job...' : 'Validating job...'}
            </div>
          )}
        </div>
      ),
    },
  ];
}

RecurringDonationMigrationView.propTypes = {
  recurringDonationMigrationJobs: PropTypes.array,
  onFileUpload: PropTypes.func,
  clientValidationErrors: PropTypes.object.isRequired,
  onDownloadDonors: PropTypes.func,
  getJobs: PropTypes.func,
};
