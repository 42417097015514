import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../../../Services/Api';
import history from '../../../Router/history';
import constants from '../../../Helpers/constants';
import utilities from '../../../Helpers/utilities';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';

export class Donors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      showAlert: false,
      donors: [],
    };

    this.renderDonorsTable = this.renderDonorsTable.bind(this);
    this.onClickEditDonor = this.onClickEditDonor.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  componentDidMount() {
    this.getDonors();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      this.getDonors();
    }
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  async getDonors() {
    const organizationId = this.props.selectedOrganization.id;
    let response;
    try {
      const getDonorsResponse = await api.getDonorsByOrganizationId(organizationId);
      if (getDonorsResponse.success) {
        const donors = getDonorsResponse.data;
        this.setState({ donors });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  }

  filterDonorsData() {
    const data = this.state.donors;
    let filteredData = [];
    if (data) {
      filteredData = data.map((donor, index) => {
        const filtered = {
          index: index + 1,
          id: donor.id,
          organizationId: donor.Organization.classyOrganizationId,
          firstName: donor.firstName,
          lastName: donor.lastName,
          email: donor.email,
          city: donor.city,
          state: donor.state,
          country: donor.country,
          createdAt: moment.utc(donor.createdAt).local().format(constants.DATES.TABLE_DATE_FORMAT),
          updatedAt: moment.utc(donor.updatedAt).local().format(constants.DATES.TABLE_DATE_FORMAT),
        };

        return filtered;
      });
    }
    return filteredData;
  }

  onClickEditDonor(selectedDonorId) {
    history.push({ pathname: `/admin/donors/${selectedDonorId}/edit` });
  }

  renderDonorsTable() {
    const data = this.state.donors;
    const columns = [
      {
        width: 50,
        Cell: (row) => (
          <button className="btn-link" onClick={() => this.onClickEditDonor(row.row.id)}>
            Edit
          </button>
        ),
        filterable: false,
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
        filterable: false,
      },
      {
        Header: 'Organization ID',
        accessor: 'Organization.classyOrganizationId',
        width: 150,
        filterable: true,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Last Name ',
        accessor: 'lastName',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Email',
        accessor: 'email',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'City',
        accessor: 'city',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'State',
        accessor: 'state',
        width: 100,
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Country',
        accessor: 'country',
        width: 100,
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: (row) => (
          <div>{row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null}</div>
        ),
        filterable: false,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: (row) => (
          <div>{row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null}</div>
        ),
        filterable: false,
      },
    ];

    return (
      <div>
        <ClassyTable
          filterable
          showWhenEmpty
          data={data || []}
          columns={columns || []}
          exportFilename={'Donors'}
          formattedExportData={this.filterDonorsData()}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {data && !data.length ? <span>No donors found.</span> : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Donors</h2>
        {this.renderDonorsTable()}
      </div>
    );
  }
}

Donors.propTypes = {
  organizations: PropTypes.array.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { organizations, selectedOrganization } = state.login;

  return {
    organizations,
    selectedOrganization,
  };
};

export default connect(mapStateToProps)(Donors);
