import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import './PaymentProfileCard.scss';
import { connect } from 'react-redux';
import ClassyButton from '../../ClassyButton/ClassyButton';
import PaymentCredForm from '../../PaymentCredForm';
import api from '../../../Services/Api';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';

const DONOR_IMPORT = 1;

class PaymentProfileCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usingTokenEx: true,
      isUpdate: false,
      processors: [],
      alertMessage: '',
      showAlert: false,
    };
  }

  componentDidMount() {
    this.getProcessors();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      this.getProcessors();
    }
  }

  getProcessors = async () => {
    const organizationId = this.props.selectedOrganization.id;
    try {
      const processorsResponse = await api.getProcessors(organizationId);
      if (processorsResponse.success) {
        let processors = processorsResponse.data;
        if (processors && Array.isArray(processors)) {
          processors = processors.filter((processor) => processor.status === 'ACTIVE');
          // sort processors by createdDate desc
          processors.sort((a, b) => {
            if (a.createdDate < b.createdDate) {
              return 1; // since descending
            }
            if (a.createdDate > b.createdDate) {
              return -1;
            }
            return 0;
          });
          this.setState({ processors }, this.shouldUseTokenEx);
        }
      }
    } catch (exception) {
      this.setAlert(`Error: ${exception.errors}\nThere was an error retrieving the campaigns for this organization.`);
    }
  };

  /**
   * returns the latest processor that is capable of processing credit cards
   * @param {Array} processors array of active processors
   */
  selectProcessor = (processors) =>
    processors.find((p) => p.paymentTypes.includes('Stripe') || p.paymentTypes.includes('CC'));

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  shouldUseTokenEx = () => {
    const { processors } = this.state;
    const currency = 'USD';
    const processorsForCurrency = processors.filter((processor) => processor.currencies.includes(currency));
    let processor = this.selectProcessor(processorsForCurrency);
    if (!processor) {
      // didn't find a processor for specific currency, look through all active processors.
      processor = this.selectProcessor(processors);
    }
    if (!processor) {
      this.setAlert(`Could not find an active processor for org ${this.organizationId} that handles credit cards.`);
      return;
    }
    const usingTokenEx = !processor.paymentTypes.includes('Stripe');
    this.setState({ usingTokenEx });
  };

  toggleUpdate = ({ success, toUpdate } = {}) => {
    if (success) {
      this.setAlert('Card Updated Successfully');
    }
    this.setState({
      isUpdate: toUpdate,
    });
  };

  render() {
    const { paymentProfile, processors, getPaymentProfilesByDonorId, donorId } = this.props;
    const { isUpdate, usingTokenEx, showAlert, alertMessage } = this.state;

    return (
      <div className="payment-profile-card__container">
        <div className="payment-profile-card__content">
          <ClassyAlert
            show={showAlert}
            alertMessage={alertMessage}
            onHide={() => {
              this.setState({ alertMessage: '', showAlert: false });
            }}
          />
          <Row>
            <Col xs={12} md={4}>
              <ControlLabel>Id:</ControlLabel>
            </Col>
            <Col xs={12} md={8}>
              <span className="payment-profile-card__field">{paymentProfile.id}</span>
            </Col>
          </Row>
          <div className="payment-profile-card__divider" />
          <Row>
            <Col xs={12} md={4}>
              <ControlLabel>ClassyPay Token Id (Last Four Digits):</ControlLabel>
            </Col>
            <Col xs={12} md={8}>
              <span className="payment-profile-card__field">{paymentProfile.classyPayTokenIdLastFour}</span>
            </Col>
          </Row>
          <div className="payment-profile-card__divider" />
          <Row>
            <Col xs={12} md={4}>
              <ControlLabel>Payment Method Type:</ControlLabel>
            </Col>
            <Col xs={12} md={8}>
              <span className="payment-profile-card__field">{paymentProfile.paymentMethodType}</span>
            </Col>
          </Row>
          {!usingTokenEx && paymentProfile.batchTypeId === DONOR_IMPORT && (
            <div>
              {(!isUpdate && (
                <Row>
                  <Col xs={12}>
                    <ClassyButton
                      title={'Update Card'}
                      style={{ margin: '15px 0', textAlign: 'center' }}
                      onClick={() => this.toggleUpdate({ toUpdate: true })}
                    />
                  </Col>
                </Row>
              )) || (
                <Row>
                  <Col xs={12}>
                    <PaymentCredForm
                      profileId={paymentProfile.id}
                      processors={processors}
                      getProcessors={this.getProcessors}
                      getPaymentProfilesByDonorId={getPaymentProfilesByDonorId}
                      donorId={donorId}
                      toggleUpdate={this.toggleUpdate}
                    />
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

PaymentProfileCard.propTypes = {
  paymentProfile: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object,
  processors: PropTypes.array,
  donorId: PropTypes.string,
  getPaymentProfilesByDonorId: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  return {
    selectedOrganization,
  };
};

export default connect(mapStateToProps, null)(PaymentProfileCard);
