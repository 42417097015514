import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Form, Row } from 'react-bootstrap';
import ClassyButton from '../ClassyButton/ClassyButton';
import TokenExCard from '../TokenExCard';
import StripeCard from '../StripeCard';
import api from '../../Services/Api';
import ClassyAlert from '../ClassyAlert/ClassyAlert';

export class PaymentCredForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      showAlert: false,
      expirationMonth: '',
      expirationYear: '',
      isExpiryDateValid: false,
      isCardValid: false,
      isSubmittingForm: false,
      tokenizedCreditCard: '',
    };
    this.baseState = this.state;
  }

  componentWillUnmount() {
    this.resetForm();
  }

  submitForm = async () => {
    const { tokenizedCreditCard } = this.state;
    const { profileId, donorId, getPaymentProfilesByDonorId, toggleUpdate } = this.props;
    this.setState({ isSubmittingForm: true });

    if (this.isFormValid() && tokenizedCreditCard) {
      try {
        const cardDetails = {
          tokenizedCreditCard,
          tokenizer: 'stripe',
          profileId,
        };

        const createPaymentCredFormResponse = await api.updateDonationsCard(cardDetails);
        this.setState({ isSubmittingForm: false });
        if (createPaymentCredFormResponse.success) {
          this.resetForm();
          getPaymentProfilesByDonorId(donorId);
          toggleUpdate({ success: true, toUpdate: false });
        } else {
          this.setAlert(
            'Unfortunately, we ran into a problem while processing this request - please contact the system administrator',
          );
        }
      } catch (exception) {
        this.setAlert(exception.errors);
      }
    } else {
      this.setState({ isSubmittingForm: false });
    }
  };

  setAlert = (alertMessage, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  getValidationStateColor = () => {
    let backgroundColor = '#fff';
    if (!this.isExpirationDateValid()) {
      backgroundColor = 'pink';
    }
    return backgroundColor;
  };

  handleTokenize = ({ tokenizedCreditCard, ccLastFour, ccType }) => {
    this.setState({ tokenizedCreditCard, ccLastFour, ccType }, () => {
      this.submitForm();
    });
  };

  handleCardValidate = ({ isCardValid }) => {
    this.setState({
      isCardValid,
    });
  };

  handleCardExpiryChange = ({ expirationMonth, expirationYear }) => {
    const toUpdate = {};
    if (expirationMonth) {
      toUpdate.expirationMonth = expirationMonth;
    }
    if (expirationYear) {
      toUpdate.expirationYear = expirationYear;
    }
    this.setState(toUpdate);
  };

  handleCardExpiryValidate = ({ isExpiryDateValid }) => {
    this.setState({ isExpiryDateValid });
  };

  registerFormSubmitHandler = (formSubmitHandler) => {
    this.formSubmitHandler = formSubmitHandler;
  };

  registerFormResetHandler = (formResetHandler) => {
    this.formResetHandler = formResetHandler;
  };

  handleSubmitButton = async (event) => {
    event.preventDefault();
    if (this.isFormValid()) {
      // When user submits form, we first tokenize the credit card
      // Only after getting the token, do we submit the data to the backend
      this.formSubmitHandler();
    }
  };

  isExpirationDateValid = () => {
    const { usingTokenEx } = this.props;
    const { isExpiryDateValid, expirationMonth, expirationYear } = this.state;
    if (!usingTokenEx) {
      return isExpiryDateValid;
    }
    let isValid = true;
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    if (expirationYear === currentYear && expirationMonth < currentMonth) {
      isValid = false;
    }
    return isValid;
  };

  isFormValid = () => {
    const { usingTokenEx } = this.props;
    const { isExpiryDateValid, expirationMonth, expirationYear, isCardValid } = this.state;
    let isValid = false;

    if (
      ((!usingTokenEx && isExpiryDateValid) || (expirationMonth && expirationYear)) &&
      this.isExpirationDateValid() &&
      isCardValid
    ) {
      isValid = true;
    }
    return isValid;
  };

  renderCard = () => {
    const { usingTokenEx } = this.props;
    const { isCardValid, expirationMonth, expirationYear } = this.state;

    if (usingTokenEx) {
      return (
        <TokenExCard
          isCardValid={isCardValid}
          expirationMonth={`${expirationMonth}`}
          expirationYear={`${expirationYear}`}
          handleCardValidate={this.handleCardValidate}
          getValidationStateColor={this.getValidationStateColor}
          handleCardExpiryChange={this.handleCardExpiryChange}
          registerFormSubmitHandler={this.registerFormSubmitHandler}
          registerFormResetHandler={this.registerFormResetHandler}
          handleTokenize={this.handleTokenize}
        />
      );
    }
    return (
      <StripeCard
        registerFormSubmitHandler={this.registerFormSubmitHandler}
        registerFormResetHandler={this.registerFormResetHandler}
        handleTokenize={this.handleTokenize}
        handleCardExpiryChange={this.handleCardExpiryChange}
        handleCardValidate={this.handleCardValidate}
        handleCardExpiryValidate={this.handleCardExpiryValidate}
      />
    );
  };

  resetForm = () => {
    if (this.formResetHandler) {
      this.formResetHandler();
    }
    this.setState(this.baseState);
    this.props.getProcessors();
    this.props.toggleUpdate({ toUpdate: false });
  };

  render() {
    const { isSubmittingForm, alertMessage, showAlert } = this.state;

    return (
      <div>
        <ClassyAlert
          show={showAlert}
          alertMessage={alertMessage}
          onHide={() => {
            this.setState({ alertMessage: '', showAlert: false });
          }}
        />
        <Form style={{ width: '100%', margin: '15px 0' }}>{this.renderCard()}</Form>
        <Row className="margin-bottom-large">
          <Col xs={12}>
            <ClassyButton
              id="PaymentCredForm__submitButton"
              title="Submit"
              onClick={this.handleSubmitButton}
              disabled={!this.isFormValid() || isSubmittingForm}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PaymentCredForm.propTypes = {
  profileId: PropTypes.number,
  donorId: PropTypes.string,
  usingTokenEx: PropTypes.string,
  match: PropTypes.object,
  getProcessors: PropTypes.func,
  toggleUpdate: PropTypes.func,
  getPaymentProfilesByDonorId: PropTypes.func,
};

export default PaymentCredForm;
