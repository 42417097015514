import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, ControlLabel, Row } from 'react-bootstrap';
import Select from 'react-select';
import DateInput from '../../DateInput/DateInput';
import constants from '../../../Helpers/constants';
import formatters from '../../../Helpers/formatters';
import './RecurringDonationCard.scss';

const RecurringDonationCard = ({
  FooterComponent,
  isEditing = false,
  onChangeInputSelect,
  onChangeInputText,
  recurringDonationObject = {},
}) => {
  const donationFrequencies = [
    { name: 'Quarterly', id: 1 },
    { name: 'Semi-Annually', id: 2 },
    { name: 'Annually', id: 3 },
    { name: 'Monthly', id: 4 },
  ];
  const donationFrequencyOptions = formatters.formatSelectOptions(donationFrequencies);
  const donationStatusOptions = formatters.formatSelectOptions(constants.DONATION_STATUSES, 'friendlyName');
  const formatDate = (date) => {
    let response;
    if (date) {
      response = moment.utc(date).local().format(constants.DATES.FORM_DATE_FORMAT);
    }
    return response;
  };

  return (
    <div
      className={`recurring-donation-card__container${isEditing ? '--selected' : ''}${
        recurringDonationObject.isEnabled ? '--is-enabled' : ''
      }`}
    >
      <div className={`recurring-donation-card__details-container${isEditing ? '--selected' : ''}`}>
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Donation Status:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            {isEditing ? (
              <Select
                className="recurring-donation-card__select"
                menuPortalTarget={document.body}
                value={formatters.getSingleOptionByValue(
                  donationStatusOptions,
                  recurringDonationObject.donationStatusId || recurringDonationObject.donationStatus.id,
                )}
                options={donationStatusOptions}
                onChange={(selected) => onChangeInputSelect(selected, 'donationStatusId')}
              />
            ) : (
              recurringDonationObject.donationStatus.friendlyName
            )}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Payment Frequency:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            {isEditing ? (
              <Select
                className="recurring-donation-card__select"
                menuPortalTarget={document.body}
                value={formatters.getSingleOptionByValue(
                  donationFrequencyOptions,
                  recurringDonationObject.paymentFrequencyId || recurringDonationObject.paymentFrequency.id,
                )}
                options={donationFrequencyOptions}
                onChange={(selected) => onChangeInputSelect(selected, 'paymentFrequencyId')}
              />
            ) : (
              recurringDonationObject.paymentFrequency.friendlyName
            )}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Amount:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            <div className={`recurring-donation-card__input-container${isEditing ? '--selected' : ''}`}>
              {isEditing ? (
                <div>
                  <input
                    className="recurring-donation-card__input-text"
                    type="text"
                    name="amount"
                    onChange={onChangeInputText}
                    value={recurringDonationObject.amount}
                  />
                  <span>{recurringDonationObject.currencyType}</span>
                </div>
              ) : (
                `${recurringDonationObject.amount} ${recurringDonationObject.currencyType}`
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Next Bill Date:</ControlLabel>
          </Col>
          <Col xs={12} md={3}>
            {isEditing ? (
              <DateInput
                popperPlacement={'right-end'}
                className="recurring-donation-card__input-date"
                selected={recurringDonationObject.nextBillDate ? moment(recurringDonationObject.nextBillDate) : ''}
                onChange={(selectedDate) => onChangeInputSelect(selectedDate, 'nextBillDate')}
                placeholder="Next Bill Date"
                minDate={moment()}
              />
            ) : (
              formatDate(recurringDonationObject.nextBillDate)
            )}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Starts At:</ControlLabel>
          </Col>
          <Col xs={12} md={3}>
            {formatDate(recurringDonationObject.startsAt)}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Ends At:</ControlLabel>
          </Col>
          <Col xs={12} md={3}>
            {isEditing ? (
              <DateInput
                popperPlacement={'right-end'}
                className="recurring-donation-card__input-date"
                selected={recurringDonationObject.endsAt ? moment(recurringDonationObject.endsAt) : ''}
                onChange={(selectedDate) => onChangeInputSelect(selectedDate, 'endsAt')}
                placeholder="Ends At"
                minDate={moment()}
              />
            ) : (
              formatDate(recurringDonationObject.endsAt)
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Campaign ID:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            {recurringDonationObject.campaignId}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Organization Name:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            {recurringDonationObject.organizationName}
          </Col>
        </Row>
        <div className="recurring-donation-card__divider" />
        <Row>
          <Col xs={12} md={4}>
            <ControlLabel>Payment Profile ID:</ControlLabel>
          </Col>
          <Col xs={12} md={8}>
            {recurringDonationObject.paymentProfileId}
          </Col>
        </Row>
      </div>
      <div className="recurring-donation-card__footer">{FooterComponent}</div>
    </div>
  );
};

RecurringDonationCard.propTypes = {
  FooterComponent: PropTypes.any,
  isEditing: PropTypes.bool,
  onChangeInputText: PropTypes.func,
  onChangeInputSelect: PropTypes.func,
  recurringDonationObject: PropTypes.object.isRequired,
};

export default RecurringDonationCard;
