import { Checkbox, FormControl, Modal } from 'react-bootstrap';
import React, { ChangeEvent, FormEventHandler, useCallback, useState } from 'react';
import { getValidationState } from './ValidationMessage';
import Throbber from '../Throbber/Throbber';
import ClassyButton from '../ClassyButton/ClassyButton';
import { SimpleValidationResult } from '../../Helpers/validators';
import { RlcCampaign } from '../../Services/Rlc/RlcCampaign';

interface EditCampaignProps {
  campaignDesignationCount: number | null;
  campaign: RlcCampaign;
  onSave: (editRlcCampaign: RlcCampaign) => void;
  onRemove: (rlcCampaignId: number) => void;
  onCancel: () => void;
  error: string | null;
  isInFlight: boolean;
}

const EditCampaign = ({
  campaign,
  onSave,
  onRemove,
  onCancel,
  error,
  campaignDesignationCount,
  isInFlight,
}: EditCampaignProps) => {
  const [usesZipCode, setUsesZipCode] = useState<boolean>(campaign.usesZipCode);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationError, setValidationError] = useState<SimpleValidationResult>({});
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState<boolean>(false);

  const onCheckedChanged: FormEventHandler<FormControl> = useCallback((event) => {
    const e = event as unknown as ChangeEvent<HTMLInputElement>;
    const value = e?.target?.checked;
    setUsesZipCode(value);
  }, []);

  const onUpdateRequested = useCallback(() => {
    onSave({ ...campaign, usesZipCode });
  }, [onSave, campaign, usesZipCode]);
  const onRemoveConfirmed = useCallback(() => {
    onRemove(campaign.id);
  }, [onRemove, campaign]);
  const onRemoveRequested = useCallback(() => {
    setShowRemoveConfirmation(true);
  }, []);
  return (
    <>
      {error && <div className="rlcDesignations__error_text">{error}</div>}
      {showRemoveConfirmation && (
        <>
          Are you sure you want to remove {campaignDesignationCount === null ? '...' : campaignDesignationCount}{' '}
          {campaignDesignationCount === 1 ? 'designation' : 'designations'} from campaign id {campaign.classyCampaignId}
          ? This will also remove the automatic designation assignment based on zip code. Please note that there may be
          customizations on your campaign that need to be removed.
          {!isInFlight && (
            <div className="submit-button text-center" style={{ marginTop: 20 }}>
              <ClassyButton
                title="Back"
                className={'secondary-button'}
                onClick={() => setShowRemoveConfirmation(false)}
              />
              <ClassyButton title="Delete" onClick={onRemoveConfirmed} />
            </div>
          )}
          {isInFlight && (
            <div className="rlcDesignations__throbber_container">
              <Throbber loading={isInFlight} />
            </div>
          )}
        </>
      )}
      {!showRemoveConfirmation && (
        <>
          <Checkbox
            checked={usesZipCode}
            onChange={onCheckedChanged}
            validationState={getValidationState(validationError, 'usesZipCode') || undefined}
          >
            Automatically assign designations based on zip code unless donor chooses a designation.
          </Checkbox>
          {isInFlight && (
            <div className="rlcDesignations__throbber_container">
              <Throbber loading={isInFlight} />
            </div>
          )}
          {!isInFlight && (
            <div className="submit-button text-center" style={{ marginTop: 20 }}>
              <ClassyButton title="Cancel" className={'secondary-button'} onClick={onCancel} />
              <ClassyButton title="Save" onClick={onUpdateRequested} />
              <ClassyButton title="Remove" className={'tertiary-button'} onClick={onRemoveRequested} />
            </div>
          )}
        </>
      )}
    </>
  );
};

type EditCampaignModalProps = EditCampaignProps & {
  isOpen: boolean;
  title: string;
  campaignDesignationCount: number | null;
};

//  https://react-bootstrap-v3.netlify.app/components/modal/
const EditCampaignModal = ({ isOpen, title, ...props }: EditCampaignModalProps) => (
  <Modal show={isOpen} onHide={props.onCancel} bsSize="large">
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EditCampaign {...props} />
    </Modal.Body>
  </Modal>
);

export default EditCampaignModal;
