import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';
import api from '../../../Services/Api';
import recurringDonationActions from '../../../Redux/RecurringDonations.redux';
import constants from '../../../Helpers/constants';
import formatters from '../../../Helpers/formatters';
import utilities from '../../../Helpers/utilities';

export class RecurringDonationsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      alertMessage: '',
    };

    this.renderRecurringDonationsTable = this.renderRecurringDonationsTable.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  componentDidMount() {
    this.getRecurring();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization.id !== selectedOrganization.id) {
      this.getRecurring();
    }
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  filterRecurringDonationsData(data) {
    let filteredData = [];
    if (data) {
      filteredData = data.map((recurringDonation, index) => {
        let filtered = {
          index: index + 1,
          organizationId: recurringDonation.organizationId,
          organizationName: recurringDonation.organizationName,
          campaignId: recurringDonation.campaignId,
          campaignName: recurringDonation.campaignName,
          donationAmount: recurringDonation.donationAmount,
          nextDonationDate: moment.utc(recurringDonation.nextBillDate).local().format('YYYY-MM-DD HH:mm:ss'),
          donationFrequency: recurringDonation.paymentFrequency,
        };

        if (recurringDonation.donationStatus) {
          filtered = {
            ...filtered,
            lastDonationStatus: recurringDonation.donationStatus,
          };
        }

        return filtered;
      });
    }
    return filteredData;
  }

  async getRecurring() {
    const organizationId = this.props.selectedOrganization.id;
    const startDate = constants.DATES.GET_RECURRING_DONATIONS_START_DATE;
    const endDate = constants.DATES.GET_RECURRING_DONATIONS_END_DATE;
    let response;
    try {
      const recurringDonationsResponse = await api.getRecurringDonations({
        organizationId,
        startDate,
        endDate,
      });
      if (recurringDonationsResponse.success) {
        const recurringDonationsData = recurringDonationsResponse.data;
        this.props.getRecurringDonationsSuccess(recurringDonationsData);
      } else {
        const error = recurringDonationsResponse;
        this.props.getRecurringDonationsFailure(error);
      }
    } catch (exception) {
      this.props.getRecurringDonationsFailure(exception);
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  }

  renderRecurringDonationsTable(data, exportFilename) {
    const columns = [
      {
        Header: 'Organization ID',
        accessor: 'organizationId',
      },
      {
        Header: 'Organization Name',
        accessor: 'organizationName',
      },
      {
        Header: 'Campaign ID',
        accessor: 'campaignId',
      },
      {
        Header: 'Campaign Name',
        accessor: 'campaignName',
      },
      {
        Header: 'Next Donation Date',
        accessor: 'nextBillDate',
        Cell: (row) => <div>{row.value ? moment.utc(row.value).local().format('YYYY-MM-DD HH:mm:ss') : null}</div>,
      },
      {
        Header: 'Donation Amount',
        accessor: 'donationAmount',
        Cell: (row) => <div>{row.value ? `$${row.value}` : null}</div>,
        sortMethod: utilities.sortCurrencyHelper,
      },
      {
        Header: 'Donation Frequency',
        accessor: 'paymentFrequency',
      },
    ];

    if (data && data[0] && data[0].donationStatus) {
      columns.push({
        Header: 'Last Donation Status',
        accessor: 'donationStatus',
      });
    }

    return (
      <ClassyTable
        showWhenEmpty
        data={data || []}
        columns={columns || []}
        exportFilename={`${exportFilename || null}RecurringDonations`}
        formattedExportData={this.filterRecurringDonationsData(data)}
        defaultPageSize={15}
        HeaderLeftComponent={<h3>{formatters.capitalizeFirstLetter(exportFilename)} Recurring Donations</h3>}
        pageSizeOptions={[15, 25, 50, 100]}
      />
    );
  }

  render() {
    const { recurringDonationsResponse } = this.props;
    const pastRecurringDonationsData = recurringDonationsResponse.past;
    const upcomingRecurringDonationsData = recurringDonationsResponse.upcoming;

    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Recurring Donations Dashboard</h2>

        {this.renderRecurringDonationsTable(pastRecurringDonationsData, 'past')}
        {pastRecurringDonationsData && !pastRecurringDonationsData.length ? (
          <span>{`No past recurring donations in last ${process.env.REACT_APP_RECURRING_DONATIONS_DAYS_BACK_FOR_START_DATE} days.`}</span>
        ) : null}
        {this.renderRecurringDonationsTable(upcomingRecurringDonationsData, 'upcoming')}
        {upcomingRecurringDonationsData && !upcomingRecurringDonationsData.length ? (
          <span>{`No upcoming recurring donations in next ${process.env.REACT_APP_RECURRING_DONATIONS_DAYS_FORWARD_FOR_END_DATE} days.`}</span>
        ) : null}
      </div>
    );
  }
}

RecurringDonationsDashboard.propTypes = {
  recurringDonationsResponse: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
  getRecurringDonationsSuccess: PropTypes.func.isRequired,
  getRecurringDonationsFailure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const { recurringDonationsError, recurringDonationsResponse } = state.recurringDonations;

  return {
    recurringDonationsError,
    recurringDonationsResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { getRecurringDonationsFailure, getRecurringDonationsSuccess } = recurringDonationActions;
  return {
    getRecurringDonationsFailure: (recurringDonationsError) =>
      dispatch(getRecurringDonationsFailure(recurringDonationsError)),
    getRecurringDonationsSuccess: (recurringDonationsResponse) =>
      dispatch(getRecurringDonationsSuccess(recurringDonationsResponse)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurringDonationsDashboard);
