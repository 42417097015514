import { createStore } from '.';

/**
 * @typedef {Object} User
 * @property {number} userId
 * @property {string} email
 * @property {string?} firstName
 * @property {string?} lastName
 * @property {boolean} isOneTimePassword
 */

// this instantiates the Redux store
const storeInstance = createStore();
export const { persistor, store } = storeInstance;
