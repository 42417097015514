import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Select from 'react-select';
import api from '../../../Services/Api';
import constants from '../../../Helpers/constants';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyButton from '../../ClassyButton/ClassyButton';
import formatters from '../../../Helpers/formatters';
import utilities from '../../../Helpers/utilities';
import validators from '../../../Helpers/validators';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = this.formatInitialState();
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOrganizationsChange = this.handleOrganizationsChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleRlcGroupIdChange = this.handleRlcGroupIdChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  formatInitialState(organizationSelectOptions, roleSelectOptions) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      isEditing: false,
      roleId: constants.ROLES.USER,
      rlcGroupId: null,
      organizationIds: [],
      organizationSelectOptions: organizationSelectOptions || [],
      pageTitle: 'Create User',
      roleSelectOptions: roleSelectOptions || [],
      rlcGroups: [],
      userOriginal: {},
      showAlert: false,
      alertMessage: '',
      userStatusOptions: [],
    };
  }

  async componentDidMount() {
    await this.getSelectOptions();
    if (this.props.match && this.props.match.params && this.props.match.params.userId) {
      this.getUserById(this.props.match.params.userId);
    }
  }

  async getSelectOptions() {
    let errorMessage;
    try {
      const organizationResponse = await api.getAllOrganizations();

      if (organizationResponse.success) {
        const selectOptions = formatters.formatSelectOptions(organizationResponse.data);
        this.setState({ organizationSelectOptions: selectOptions });
      } else {
        errorMessage = 'We were unable to retrieve organizations';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }

    errorMessage = undefined;
    try {
      const rolesResponse = await api.getAllRoles();

      if (rolesResponse.success) {
        const selectOptions = formatters.formatSelectOptions(rolesResponse.data);
        this.setState({ roleSelectOptions: selectOptions });
      } else {
        errorMessage = 'We were unable to retrieve roles';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }

    try {
      const rlcGroupResponse = await api.getRlcGroups();

      if (rlcGroupResponse.success) {
        const rlcGroups = formatters.formatSelectOptions(rlcGroupResponse.data, 'name');
        this.setState({ rlcGroups });
      } else {
        errorMessage = 'We were unable to retrieve RLC Groups';
      }
    } catch (exception) {
      errorMessage = exception.errors;
    }
    if (errorMessage) {
      this.setAlert(errorMessage);
    }
  }

  async getUserById(userId) {
    let message = null;
    let { user } = this.props;
    try {
      const getUserByIdResponse = await api.getUserById(userId);

      if (getUserByIdResponse.success) {
        user = getUserByIdResponse.data;
        user.organizationIds = user.Organizations.map((org) => org.id);
        user.userStatus = user.isOneTimePassword ? constants.PENDING_STATUS.value : Number(user.isEnabled);
        this.setState({
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          roleId: user.roleId,
          rlcGroupId: user.rlcGroupId || null,
          organizationIds: user.organizationIds,
          isEditing: true,
          pageTitle: 'Edit User',
          userId: user.id,
          isPending: user.isOneTimePassword,
          userStatus: user.userStatus,
          userOriginal: user,
          userStatusOptions: constants.USER_STATUS.getUserStatusOptions(user.isOneTimePassword),
        });
      }
    } catch (exception) {
      message = exception.errors;
    }
    if (message) {
      this.setAlert(message);
    }

    return user;
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleOrganizationsChange(selectedOrganizations) {
    const organizationIds = selectedOrganizations.map((org) => org.value);
    this.setState({ organizationIds });
  }

  handleRoleChange(selectedRole) {
    this.setState({ roleId: selectedRole.value });
  }

  handleRlcGroupIdChange(selectedGroup) {
    this.setState({ rlcGroupId: selectedGroup?.value ?? null });
  }

  handleStatusChange = (selectedStatus) => {
    this.setState({ userStatus: selectedStatus.value });
  };

  isFormValid() {
    let response = false;
    if (this.isFormChanged()) {
      if (
        validators.getValidStateName(this.state.firstName) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateName(this.state.lastName) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateEmail(this.state.email) === constants.VALIDATION_STATES.SUCCESS &&
        validators.getValidStateIdArray(this.state.organizationIds) === constants.VALIDATION_STATES.SUCCESS
      ) {
        response = true;
      }
    }
    return response;
  }

  isFormChanged() {
    let isChanged = false;
    if (
      this.state.email !== this.state.userOriginal.email ||
      this.state.firstName !== this.state.userOriginal.firstName ||
      this.state.lastName !== this.state.userOriginal.lastName ||
      this.state.roleId !== this.state.userOriginal.roleId ||
      this.state.rlcGroupId !== this.state.userOriginal.rlcGroupId ||
      this.state.userStatus !== this.state.userOriginal.userStatus ||
      !utilities.arraysEqual(this.state.organizationIds, this.state.userOriginal.organizationIds)
    ) {
      isChanged = true;
    }
    return isChanged;
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.isFormValid()) {
      let message;
      try {
        const userObj = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          organizationIds: this.state.organizationIds,
          roleId: this.state.roleId,
          rlcGroupId: this.state.rlcGroupId,
        };
        let userResponse;
        if (this.state.isEditing) {
          const editUserId = this.state.userId;
          if (this.state.userStatus === constants.PENDING_STATUS.value) {
            userObj.isEnabled = true;
            userObj.isOneTimePassword = true;
          } else {
            userObj.isEnabled = !!this.state.userStatus;
            userObj.isOneTimePassword = false;
          }
          userResponse = await api.editUser(userObj, editUserId);
        } else {
          const changePasswordTemplateUrl = process.env.REACT_APP_CLASSY_SERVICES_URL_CHANGE_PASSWORD_TEMPLATE;
          userResponse = await api.createUser({
            user: userObj,
            url: changePasswordTemplateUrl,
          });
        }
        if (userResponse.success) {
          message = userResponse.data;
          if (this.state.isEditing) {
            this.setState({ userOriginal: userObj });
          } else {
            // Reset form, passing in the current selectOptions to preserve data
            this.setState(this.formatInitialState(this.state.organizationSelectOptions, this.state.roleSelectOptions));
          }
        } else {
          message = constants.MESSAGES.ERROR_DEFAULT;
        }
      } catch (exception) {
        message = exception.errors;
      }
      if (message) {
        this.setAlert(message);
      }
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      rlcGroupId,
      organizationIds,
      organizationSelectOptions,
      rlcGroups,
      pageTitle,
      roleId,
      roleSelectOptions,
      userStatus,
      isEditing,
      userStatusOptions,
    } = this.state;
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">{pageTitle}</h2>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="firstName" validationState={validators.getValidStateName(firstName)}>
            <ControlLabel>First Name</ControlLabel>
            <FormControl
              name="firstName"
              type="text"
              value={firstName}
              placeholder="Jane"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="lastName" validationState={validators.getValidStateName(lastName)}>
            <ControlLabel>Last Name</ControlLabel>
            <FormControl
              name="lastName"
              type="text"
              value={lastName}
              placeholder="Doe"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="email" validationState={validators.getValidStateEmail(email)}>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              name="email"
              type="email"
              value={email}
              placeholder="example@classy.org"
              onChange={this.handleInputChange}
            />
            <FormControl.Feedback />
          </FormGroup>

          <FormGroup controlId="organizationsSelect" validationState={validators.getValidStateIdArray(organizationIds)}>
            <ControlLabel>Organizations</ControlLabel>
            <FormControl componentClass="react-select" name="organizations">
              <Select
                isMulti
                options={organizationSelectOptions}
                value={formatters.getMultipleOptionsByValue(organizationSelectOptions, organizationIds)}
                onChange={this.handleOrganizationsChange}
              />
            </FormControl>
          </FormGroup>

          <FormGroup controlId="rolesSelect">
            <ControlLabel>Roles</ControlLabel>
            <FormControl componentClass="react-select" name="roles">
              <Select
                options={roleSelectOptions}
                value={formatters.getSingleOptionByValue(roleSelectOptions, roleId)}
                onChange={this.handleRoleChange}
              />
            </FormControl>
          </FormGroup>

          {isEditing && (
            <FormGroup controlId="userStatus">
              <ControlLabel>Status</ControlLabel>
              <FormControl componentClass="react-select" name="status">
                <Select
                  options={userStatusOptions}
                  value={formatters.getSingleOptionByValue(userStatusOptions, userStatus)}
                  onChange={this.handleStatusChange}
                />
              </FormControl>
            </FormGroup>
          )}

          <FormGroup controlId="rlcGroupSelect">
            <ControlLabel>RLC Group</ControlLabel>
            <FormControl componentClass="react-select" name="rlcGroup">
              <Select
                isClearable
                options={rlcGroups}
                value={formatters.getSingleOptionByValue(rlcGroups, rlcGroupId)}
                onChange={this.handleRlcGroupIdChange}
              />
            </FormControl>
          </FormGroup>
        </form>
        <ClassyButton title="Submit" disabled={!this.isFormValid()} onClick={this.handleSubmit} />
      </div>
    );
  }
}
AddUser.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  organizations: PropTypes.array.isRequired,
  userId: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { organizations, user } = state.login;

  return {
    organizations,
    user,
  };
};

export default connect(mapStateToProps)(AddUser);
