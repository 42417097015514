import httpStatusCodes from 'http-status-codes';
import React, { ChangeEvent, Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { CellInfo, Column } from 'react-table';
import { Prompt } from 'react-router';
import { get } from 'lodash';
import { saveAs } from 'file-saver';
import papaParse from 'papaparse';
import ClassyAlert from '../ClassyAlert/ClassyAlert';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTableCheckbox from '../ClassyTableCheckbox/ClassyTableCheckbox';
import ClassySelect from '../ClassySelect/ClassySelect';
import ClassyTable from '../ClassyTable/ClassyTable';
import ClassyTableCalendar from '../ClassyTableCalendar/ClassyTableCalendar';
import ClassyTableCell from '../ClassyTableCell/ClassyTableCell';
import FileInput from '../FileInput/FileInput';
import Throbber from '../Throbber/Throbber';
import constants from '../../Helpers/constants';
import formatters from '../../Helpers/formatters';
import localization from '../../Helpers/localization';
import OfflineDonationActions from '../../Redux/OfflineDonations.redux';
import LoginActions from '../../Redux/Login.redux';
import api from '../../Services/Api';
import HelpComponent from '../HelpComponent/HelpComponent';
import './OfflineDonations.scss';
import { OfflineDonationValidationError } from '../../Services/OfflineDonations';
import { FundraisingPageDropDown } from '../../Services/FundraisingPages';
import { FundraisingTeamDropDown } from '../../Services/FundraisingTeams';
import {
  FormattedCampaign,
  OfflineDonation,
  OfflineDonationsProps,
  OfflineDonationsState,
  OfflineDonationsStoreProps,
} from './Types';
import { DetailedErrors } from './DetailedErrors';
import { formatRowError, validateFileImport, validateRows } from './Validation';
import { csvRowOffset } from './Utils';

export const filterTransaction = (transaction: any) => ({
  ...transaction,
  amount: transaction.amount?.replace(/[$,]/gi, ''),
});

export const filterTransactions = (transactions: Array<any>) => transactions.map(filterTransaction);

export class OfflineDonations extends Component<OfflineDonationsProps, OfflineDonationsState> {
  private readonly pagesMap;
  private readonly formattedPagesMap;
  private readonly teamsMap;
  private readonly formattedTeamsMap;
  private readonly columns: Array<Column<any>>;
  private readonly apiAbortController;

  constructor(props: OfflineDonationsProps) {
    super(props);
    this.apiAbortController = api.createAbortController();
    this.state = {
      errors: {},
      showAlert: false,
      alertMessage: '',
      isParsingTemplate: false,
      isOrgDataLoading: true,
      rowErrors: [],
    };

    // data structures used to avoid having to iterate over fundraiser pages for every row
    this.pagesMap = {};
    this.formattedPagesMap = {};
    this.teamsMap = {};
    this.formattedTeamsMap = {};

    this.columns = [
      {
        Cell: (row) =>
          typeof row.index !== 'undefined' ? (
            <div className="non-editable-cell" onClick={() => this.deleteTemplateRow(row.index)}>
              <i className="fa fa-minus-circle" />
            </div>
          ) : null,
        width: 50,
        resizable: false,
      },
      {
        Header: 'CSV Row',
        Cell: (row) => (
          <div className="non-editable-cell">
            {typeof row.index !== 'undefined' ? csvRowOffset(row.index + 1) : null}
          </div>
        ),
        width: 90,
        resizable: false,
      },
      {
        Header: () => <div>Source</div>,
        accessor: 'source',
        Cell: (row) =>
          this.renderCell(
            row,
            constants.CELL_TYPES.SELECT,
            constants.OFFLINE_DONATIONS.DONATION_SOURCE,
            true,
            false,
            false,
            true,
          ),
        width: 150,
      },
      {
        Header: () => <div>First Name</div>,
        accessor: 'firstName',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, true, false, row.row.source === 'company'),
        width: 150,
      },
      {
        Header: () => <div>Last Name</div>,
        accessor: 'lastName',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, true, false, row.row.source === 'company'),
        width: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false, row.row.source === 'company'),
        width: 200,
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false, row.row.source === 'company'),
        width: 150,
      },
      {
        Header: () => <div>Company</div>,
        accessor: 'companyName',
        Cell: (row) =>
          this.renderCell(
            row,
            constants.CELL_TYPES.TEXTBOX,
            null,
            row.row.source === 'company',
            false,
            !row.row.source || row.row.source === 'individual',
          ),
        width: 150,
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX),
        width: 200,
      },
      {
        Header: 'City',
        accessor: 'city',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX),
      },
      {
        Header: 'State',
        accessor: 'state',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX),
      },
      {
        Header: 'Country',
        accessor: 'country',
        Cell: (row) =>
          this.renderCell(
            row,
            constants.CELL_TYPES.SELECT,
            localization.COUNTRIES.map((country) => ({
              value: country.code,
              label: country.code,
            })),
          ),
        width: 150,
      },
      {
        Header: 'Zip',
        accessor: 'zip',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX),
      },
      {
        Header: () => <div>Currency</div>,
        accessor: 'currency',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.SELECT, constants.OFFLINE_DONATIONS.ACCEPTED_CURRENCIES, true),
        width: 150,
      },
      {
        Header: () => <div>Amount</div>,
        accessor: 'amount',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, true, true),
      },
      {
        Header: 'Anonymous',
        accessor: 'makeDonationAnonymous',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.CHECKBOX),
        width: 100,
      },
      {
        Header: 'Description',
        accessor: 'donorComment',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX),
      },
      {
        Header: 'Payment Type',
        accessor: 'paymentType',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.SELECT, constants.OFFLINE_DONATIONS.PAYMENT_TYPES),
        width: 150,
      },
      {
        Header: 'Check #',
        accessor: 'checkNumber',
        Cell: (row) =>
          this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true, row.row.paymentType !== 'check'),
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.CALENDAR),
        width: 150,
      },
      {
        Header: () => <div>Campaign</div>,
        accessor: 'campaignId',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.SELECT, this.formatCampaigns(), true),
        width: 150,
      },
      {
        Header: 'Fundraiser/Team',
        accessor: 'fundraiserId',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.SELECT, this.formatFundraiserData(row)),
        width: 150,
      },
      {
        Header: 'Program Designation Id',
        accessor: 'programDesignationId',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.SELECT, this.formatProgramDesignations()),
        width: 150,
      },
      {
        Header: 'SalesForce',
        accessor: 'salesForce',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.CHECKBOX),
        width: 100,
      },
      {
        Header: () => <div>Question One Id</div>,
        accessor: 'q1Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question One Answer</div>,
        accessor: 'q1Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Two Id</div>,
        accessor: 'q2Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Two Answer</div>,
        accessor: 'q2Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Three Id</div>,
        accessor: 'q3Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Three Answer</div>,
        accessor: 'q3Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Four Id</div>,
        accessor: 'q4Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Four Answer</div>,
        accessor: 'q4Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Five Id</div>,
        accessor: 'q5Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Five Answer</div>,
        accessor: 'q5Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Six Id</div>,
        accessor: 'q6Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Six Answer</div>,
        accessor: 'q6Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Seven Id</div>,
        accessor: 'q7Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Seven Answer</div>,
        accessor: 'q7Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Eight Id</div>,
        accessor: 'q8Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Eight Answer</div>,
        accessor: 'q8Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Nine Id</div>,
        accessor: 'q9Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Nine Answer</div>,
        accessor: 'q9Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
      {
        Header: () => <div>Question Ten Id</div>,
        accessor: 'q10Id',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, true),
        width: 150,
      },
      {
        Header: () => <div>Question Ten Answer</div>,
        accessor: 'q10Answer',
        Cell: (row) => this.renderCell(row, constants.CELL_TYPES.TEXTBOX, null, false, false),
        width: 150,
      },
    ];
  }

  componentDidMount() {
    this.getOrganizationData().catch((e) => {
      // TODO: handle exception
      // eslint-disable-next-line no-console
      console.log(e);
    });
    const { templateData } = this.props;
    if (!templateData.length) {
      this.addTemplateRow();
    }
  }

  componentDidUpdate(prevProps: OfflineDonationsProps) {
    const {
      selectOrganization,
      selectedOrganization,
      updateOfflineTemplate,
      templateData,
      createOfflineDonationsResponse,
      createOfflineDonationsSuccess,
    } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      const confirmOrganizationChange = () =>
        typeof window !== 'undefined' &&
        window.confirm('Are you sure you want to change organizations? Your work will not be saved.');
      if (prevProps.templateData.length && !confirmOrganizationChange()) {
        selectOrganization(prevProps.selectedOrganization);
        updateOfflineTemplate(prevProps.templateData);
      } else {
        // eslint-disable-next-line no-unused-vars
        this.getOrganizationData().catch(() => {
          // TODO: handle exception
        });
      }
    }
    if (!templateData.length) {
      this.addTemplateRow();
    }

    if (createOfflineDonationsResponse && createOfflineDonationsResponse.text) {
      this.setAlert(createOfflineDonationsResponse.text);
      createOfflineDonationsSuccess({});
    }
  }

  componentWillUnmount() {
    const { clearOrganizationData, clearTemplateData } = this.props;
    clearOrganizationData();
    clearTemplateData();
    this.apiAbortController.abort();
  }

  setAlert = (alertMessage: string | Array<string>, showAlert = true) => {
    this.setState({ alertMessage, showAlert });
  };

  addTemplateRow = () => {
    const { addTemplateRow, templateRow } = this.props;
    const row = Object.assign({}, templateRow);

    addTemplateRow(row);
  };

  deleteTemplateRow = (rowIndex: number) => {
    const { templateData, updateOfflineTemplate } = this.props;
    const newData = [...templateData.slice(0, rowIndex), ...templateData.slice(rowIndex + 1)];
    const { errors } = this.state;
    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((error) => {
        if (error.startsWith(rowIndex as any)) {
          delete errors[error];
        }
      });
    }
    updateOfflineTemplate(newData);
  };

  clearTable = () => {
    const { clearTemplateData } = this.props;
    clearTemplateData();
  };

  formatCampaigns = (): Array<FormattedCampaign> => {
    const { campaigns } = this.props;
    let formattedCampaigns: Array<FormattedCampaign> = [];
    if (campaigns && campaigns.length) {
      // for each campaign object, this is pulling out the id and name properties and
      // assigning each to the value and label properties, respectively, so they can be read by the select component
      formattedCampaigns = campaigns.map((campaign: any) =>
        (({ id, name }) => ({
          value: id,
          label: name,
        }))(campaign),
      );
    }
    return formattedCampaigns;
  };

  /**
   * Returns a filtered list of fundraiser pages or teams by campaign Id. If the filtered data is already in a local hash table, then we simply return it.
   * Otherwise, we iterate over all fundraiser data for the organization (either pages or teams), and filter by campaign id. We memoize the process by storing the results in the hash table.
   * @param {Number} campaignId The campaign id to filter by
   * @param {Array} fundraiserArr A collection of an organization's fundraiser pages or teams. It's always either one or the other.
   * @param {Object} mapData A hash table where the key is a campaign id and the value is all fundraiser pages associated to that campaign (alternatively teams)
   * @returns {Array}
   */
  getFilteredFundraisers(
    campaignId: number,
    fundraiserArr: Array<FundraisingTeamDropDown | FundraisingPageDropDown>,
    mapData: Record<number, any>,
  ) {
    if (mapData[campaignId]) {
      return mapData[campaignId];
    }
    mapData[campaignId] = fundraiserArr.filter((item) => Number(item['campaign_id']) === Number(campaignId)); // eslint-disable-line

    return mapData[campaignId];
  }

  /**
   * Returns a collection of fundraiser page or team objects that have value, label, and fundraiserType properties. If the formatted data is already in a local hash table, then we simply return it.
   * Otherwise, we iterate over all items in the filteredFundraiserArr collection and create the objects. We memoize the process by storing the results in the mapData hash table.
   * @param {Number} campaignId The campaignId to search the hash table by or to create a new entry in the table.
   * @param {Array} filteredFundraiserArr A collection of a campaign's fundraiser pages or teams. It's always either one or the other.
   * @param {String} fundraiserType the fundraiser type to use (ex: fundraiserPageId, fundraiserTeamId)
   * @param {Object} mapData A hash table where the key is a campaignId, and the value is a collection of formatted fundraiser pages or fundraiser teams.
   * @returns {Array}
   */
  getFormattedFundraisers(
    campaignId: number,
    filteredFundraiserArr: Array<any>,
    fundraiserType: string,
    mapData: Record<number, any>,
  ) {
    if (mapData[campaignId]) {
      return mapData[campaignId];
    }
    mapData[campaignId] = filteredFundraiserArr.map((item) => this.formatFundraiserSelectData(item, fundraiserType)); // eslint-disable-line

    return mapData[campaignId]; // eslint-disable-line
  }

  /**
   * Helper function that creates the object required by the select dropdown for the fundraiser pages/teams section
   * @param {Number} id Destructured fundraiser page or team id
   * @param {String} title Destructured title of the fundraiser page, teams do not have a "title"
   * @param {String} name Destructured name of the fundraiser team, pages do not have "name".
   * @param {String} fundraiserType The fundraiser type to use (ex: fundraiserPageId, fundraiserTeamId)
   * @returns {Object}
   */
  formatFundraiserSelectData({ id, intro_text, name }: any, fundraiserType: string) {
    // eslint-disable-line camelcase
    return {
      value: id,
      // pages have intro_text, while teams have a name
      // the intro_text can have html tags, so the regex will strip them out
      label: name || (intro_text && intro_text.replace(/(<([^>]+)>)/gi, '')), // eslint-disable-line camelcase
      fundraiserType,
    };
  }

  /**
   * Searches both fundraiser page and team collections for a matching fundraiserId.
   * If one is found in either collection, we know that the fundraiser is of that type.
   * @returns {String}
   * @param formattedRow
   */
  getFundraiserType(formattedRow: any) {
    const { fundraiserPages, fundraiserTeams } = this.props;
    if (fundraiserPages) {
      const filteredPages = this.getFilteredFundraisers(formattedRow.campaignId, fundraiserPages, this.pagesMap);
      if (filteredPages.some((page: any) => Number(page.id) === Number(formattedRow.fundraiserId))) {
        return constants.OFFLINE_DONATIONS.FUNDRAISER_TYPES.PAGE;
      }
    }
    if (fundraiserTeams) {
      const filteredTeams = this.getFilteredFundraisers(formattedRow.campaignId, fundraiserTeams, this.teamsMap);

      if (filteredTeams.some((team: any) => Number(team.id) === Number(formattedRow.fundraiserId))) {
        return constants.OFFLINE_DONATIONS.FUNDRAISER_TYPES.TEAM;
      }
    }
    return formattedRow.fundraiserType;
  }

  formatFundraiserData = (row: CellInfo) => {
    const { fundraiserPages, fundraiserTeams, templateData } = this.props;
    const selectedCampaignId = get(templateData, [[row.index] as any, 'campaignId']) as any;
    // const selectedCampaignId = templateData[row.index]?.campaignId;
    let formattedFundraiserData: Array<any> = [];
    if (selectedCampaignId) {
      if (fundraiserPages && fundraiserPages.length) {
        const filteredPages = this.getFilteredFundraisers(selectedCampaignId, fundraiserPages, this.pagesMap);

        const formattedFundraiserPages = this.getFormattedFundraisers(
          selectedCampaignId,
          filteredPages,
          constants.OFFLINE_DONATIONS.FUNDRAISER_TYPES.PAGE,
          this.formattedPagesMap,
        );

        const pages = { label: 'Pages', options: formattedFundraiserPages };

        formattedFundraiserData = [...formattedFundraiserData, pages];
      }

      if (fundraiserTeams && fundraiserTeams.length) {
        const filteredTeams = this.getFilteredFundraisers(selectedCampaignId, fundraiserTeams, this.teamsMap);

        const formattedFundraiserTeams = this.getFormattedFundraisers(
          selectedCampaignId,
          filteredTeams,
          constants.OFFLINE_DONATIONS.FUNDRAISER_TYPES.TEAM,
          this.formattedTeamsMap,
        );

        const teams = { label: 'Teams', options: formattedFundraiserTeams };

        formattedFundraiserData = [...formattedFundraiserData, teams];
      }
    }
    return formattedFundraiserData;
  };

  formatProgramDesignations() {
    const { programDesignations } = this.props;
    let formattedProgramDesignations = [];
    if (programDesignations && programDesignations.length) {
      formattedProgramDesignations = formatters.formatSelectOptions(this.props.programDesignations);
    }
    return formattedProgramDesignations;
  }

  formatOrgDataErrorMessage(requestType: any, error: any, isRequired: boolean) {
    let message = `This organization is not configured to return ${requestType}s.`;

    if (error.errors && error.errors.length) {
      message = `Unfortunately, we were unable to retrieve ${requestType}s: ${error.errors.join(',')}`;
    }

    if (!isRequired) {
      message = `${message} You can still process the offline donations, however they will not be assigned to a ${requestType}.`;
    }

    return message;
  }

  async getCampaigns() {
    const organizationId = this.props.selectedOrganization.id;
    let message;
    try {
      const campaignsResponse = await api.getCampaigns(organizationId, false, {
        signal: this.apiAbortController.signal,
      });
      if (campaignsResponse.success) {
        let campaigns: any = campaignsResponse.data;
        if (!Array.isArray(campaigns)) {
          // is this ever true???
          campaigns = [campaigns];
        }
        this.props.setCampaigns(campaigns);
      }
    } catch (error) {
      message = this.formatOrgDataErrorMessage('campaign', error, true);
    }

    if (message) {
      this.setAlert(message);
    }
  }

  async getFundraiserPages() {
    const organizationId = this.props.selectedOrganization.id;
    let message;
    try {
      const fundraiserPagesResponse = await api.getFundraiserPages(organizationId, {
        signal: this.apiAbortController.signal,
      });

      if (fundraiserPagesResponse.success) {
        let fundraiserPages: any = fundraiserPagesResponse.data;
        if (!Array.isArray(fundraiserPages)) {
          // TODO: Is this ever true??
          fundraiserPages = [fundraiserPages];
        }
        this.props.setFundraiserPages(fundraiserPages);
      }
    } catch (error) {
      message = this.formatOrgDataErrorMessage('fundraiser page', error, false);
    }

    if (message) {
      this.setAlert(message);
    }
  }

  async getFundraiserTeams() {
    const organizationId = this.props.selectedOrganization.id;
    let message;
    try {
      const fundraiserTeamsResponse = await api.getFundraiserTeams(organizationId, {
        signal: this.apiAbortController.signal,
      });
      if (fundraiserTeamsResponse.success) {
        let fundraiserTeams: any = fundraiserTeamsResponse.data;
        if (!Array.isArray(fundraiserTeams)) {
          // TODO: is this ever true?
          fundraiserTeams = [fundraiserTeams];
        }
        this.props.setFundraiserTeams(fundraiserTeams);
      }
    } catch (error) {
      message = this.formatOrgDataErrorMessage('fundraiser team', error, false);
    }

    if (message) {
      this.setAlert(message);
    }
  }

  async getProgramDesignations() {
    const organizationId = this.props.selectedOrganization.id;
    let message;
    try {
      const programDesignationsResponse = await api.getClassyProgramDesignations(organizationId, {
        signal: this.apiAbortController.signal,
      });
      if (programDesignationsResponse.success) {
        const programDesignations = programDesignationsResponse.data;
        this.props.setProgramDesignations(programDesignations);
      } else {
        const error = programDesignationsResponse.errors || 'unknown error';
        message = 'Unable to retrieve the program designations: ' + error;
      }
    } catch (error) {
      message = this.formatOrgDataErrorMessage('program designation', error, false);
    }

    if (message) {
      this.setAlert(message);
    }
  }

  async getOrganizationData() {
    this.setState({ isOrgDataLoading: true });
    await Promise.all([
      this.getCampaigns(),
      this.getFundraiserPages(),
      this.getFundraiserTeams(),
      this.getProgramDesignations(),
    ]);
    this.setState({ isOrgDataLoading: false });
  }

  onChangeTableCell = (row: CellInfo, value: any, isValid: boolean) => {
    const { templateData } = this.props;
    const data = [...templateData];
    data[row.index][row.column.id as any] = value;
    this.setErrorState(row, isValid);
  };

  onChangeSelect = (row: CellInfo, selected: any, isValid: boolean) => {
    const { templateData, updateOfflineTemplate } = this.props;
    const data = [...templateData];
    if (row.column.id === 'campaignId') {
      data[row.index].fundraiserId = null;
      data[row.index].fundraiserType = null;
    }
    if (row.column.id === 'fundraiserId' && selected.fundraiserType) {
      data[row.index].fundraiserType = selected.fundraiserType;
    }
    if (row.column.id === 'paymentType') {
      data[row.index].checkNumber = null;
    }
    if (row.column.id === 'source') {
      data[row.index].firstName = null;
      data[row.index].lastName = null;
      data[row.index].email = null;
      data[row.index].companyName = null;
    }
    if (selected.length) {
      data[row.index][row.column.id as any] = selected.map((option: any) => option.value);
    } else {
      data[row.index][row.column.id as any] = selected.value;
    }
    this.setErrorState(row, isValid);
    updateOfflineTemplate(data);
  };

  onChangeDate = (row: CellInfo, date: any) => {
    const { templateData, updateOfflineTemplate } = this.props;
    const data = [...templateData];
    data[row.index][row.column.id as any] = date;
    updateOfflineTemplate(data);
  };

  onCheck = (row: CellInfo, checked: any) => {
    const { templateData, updateOfflineTemplate } = this.props;
    const data = [...templateData];
    data[row.index][row.column.id as any] = checked;
    updateOfflineTemplate(data);
  };

  filterProperties(donationsArray: Array<any>) {
    return donationsArray.map((donation) => {
      delete donation.campaignName; // eslint-disable-line
      return donation;
    });
  }

  /**
   * Check the state of the table to determine if it's been updated and currently has data
   */
  hasData() {
    const { templateData } = this.props;

    let hasData = false;

    // We only need to check for modified values of each column if we have a single row, otherwise having multiple rows means the table has been touched
    if (templateData && templateData.length === 1) {
      const singleRecord = templateData[0];

      // For each column, check if the single record has a value specified
      const columnsWithData = Object.keys(singleRecord).filter((key: any) => {
        // If this is true then the column has data, booleans default to false so it works for this as well
        let columnHasData: string | null | boolean = singleRecord[key];

        // Don't count the "source" column because it has a default value
        if (key === 'source') {
          columnHasData = false;
        }

        return columnHasData;
      });

      // If any columns have data, then the single record has data and therefore the table does
      hasData = columnsWithData.length > 0;
    } else if (templateData && templateData.length > 1) {
      hasData = true;
    }

    return hasData;
  }

  massageRows(data: Array<Partial<OfflineDonation>>): Array<OfflineDonation> {
    return data.map(
      (transaction: Partial<OfflineDonation>) =>
        ({
          ...transaction,
          source: transaction.source ? transaction.source.toLowerCase() : 'individual',
          fundraiserType: this.getFundraiserType(transaction),
        } as OfflineDonation),
    );
  }

  onDownloadTemplate = () => {
    const { templateData, templateRow } = this.props;
    const csv = papaParse.unparse(templateData.length ? this.filterProperties(templateData) : [templateRow]);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = 'offlineDonationsTemplate.csv';

    saveAs(csvBlob, csvName);
  };

  onUploadTemplate = (file: any) => {
    this.setState({ rowErrors: [] });
    if (file) {
      const { templateData, updateOfflineTemplate } = this.props;
      this.setState({ isParsingTemplate: true });

      papaParse.parse(file, {
        dynamicTyping: false,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results && results.data) {
            const parsedTemplate: Array<any> = filterTransactions(results.data);

            const fileValidationErrors = validateFileImport(parsedTemplate, this.props.templateRow);
            if (fileValidationErrors && fileValidationErrors.length > 0) {
              const validationMessage = fileValidationErrors.join('.\n');
              this.setAlert(`Please correct the following errors before submitting: ${validationMessage}.`);
            } else {
              const rowValidationErrors = validateRows(parsedTemplate, this.formatCampaigns());
              this.setState({ rowErrors: rowValidationErrors });
              if (rowValidationErrors && rowValidationErrors.length > 0) {
                const validationMessages = rowValidationErrors.map(formatRowError);
                this.setAlert(['Please correct the following errors before submitting:', ...validationMessages]);
              } else {
                let newTemplateData = this.massageRows(parsedTemplate);
                // If we currently have data in the table, let's merge it with our new data
                if (this.hasData()) {
                  // Creating a new array and concatenating the new template data to not modify the current data in props
                  newTemplateData = [...templateData].concat(newTemplateData);
                }

                updateOfflineTemplate(newTemplateData);
              }
            }
          }
          this.setState({ isParsingTemplate: false });
        },
      });
    }
  };

  renderCell = (
    row: CellInfo,
    cellType: string,
    options?: any,
    isRequired?: boolean,
    isNumeric?: boolean,
    isDisabled?: boolean,
    selectFirstOption?: boolean,
  ) => {
    const { templateData } = this.props;
    let value = get(templateData, [[row.index], [row.column.id]] as any);
    switch (cellType) {
      case constants.CELL_TYPES.TEXTBOX:
        return (
          <ClassyTableCell
            isRequired={isRequired}
            isNumeric={isNumeric}
            row={row}
            onChange={this.onChangeTableCell}
            isDisabled={isDisabled}
            value={value}
          />
        );
      case constants.CELL_TYPES.SELECT: {
        if (selectFirstOption && value === undefined && options.length > 0) {
          value = get(options, '0.value');
        }
        return (
          <ClassySelect
            isRequired={isRequired}
            row={row}
            valueSingle={value}
            onChange={this.onChangeSelect}
            isDisabled={isDisabled}
            options={options}
          />
        );
      }
      case constants.CELL_TYPES.MULTI_SELECT:
        return (
          <ClassySelect
            isMulti
            isRequired={isRequired}
            row={row}
            valueMultiple={value}
            onChange={this.onChangeSelect}
            isDisabled={isDisabled}
            options={options}
          />
        );
      case constants.CELL_TYPES.CHECKBOX:
        return <ClassyTableCheckbox row={row} onChange={this.onCheck} checked={value} />;
      case constants.CELL_TYPES.CALENDAR:
        return (
          <div className="flexRow">
            <ClassyTableCalendar
              row={row}
              dateFormat={constants.DATES.OFFLINE_DONATIONS_DATE_FORMAT}
              selected={value}
              onChange={this.onChangeDate}
            />
            <i className="fa fa-calendar offline-donations__table-cell-icon" />
          </div>
        );
      default:
        return null;
    }
  };

  renderTemplate = () => {
    const { errors } = this.state;
    const { templateData } = this.props;
    const hasErrors = Object.keys(errors).filter((key) => errors[key]).length > 0;
    const incompleteRows = templateData.filter((row) => {
      let requiredKeys = constants.OFFLINE_DONATIONS.REQUIRED_FIELDS_INDIVIDUAL;
      if (row.source === 'company') {
        requiredKeys = constants.OFFLINE_DONATIONS.REQUIRED_FIELDS_COMPANY;
      }
      return requiredKeys.filter((key) => !(row as any)[key]).length;
    });
    const isComplete = incompleteRows.length === 0;

    return (
      <div className="offline-donations__table-container">
        {templateData ? (
          <Prompt when={!!templateData.length} message="Are you sure you want to leave? Your work will not be saved." />
        ) : null}
        {this.state.isParsingTemplate || this.props.loadingOfflineDonations ? this.renderThrobber() : null}
        <span>*Required fields are highlighted red when left blank</span>
        <ClassyTable
          className="offline-donations__table"
          showWhenEmpty
          data={templateData.length > 0 ? templateData : []}
          columns={this.columns || []}
          getTdProps={() => ({
            style: { padding: 5, display: 'block', marginBottom: 5 },
          })}
          FooterLeftComponent={
            <span className="padding-large" onClick={this.addTemplateRow}>
              <i className="fa fa-plus-circle" />
              <button className="padding-medium btn-link">Add Row</button>
            </span>
          }
          clearTable={this.clearTable}
        />
        <div className="flexRow">
          <ClassyButton
            className="offline-donations__submit-button"
            disabled={!templateData.length || hasErrors || !isComplete || this.props.loadingOfflineDonations}
            title="Submit"
            onClick={this.createOfflineDonations}
          />
        </div>
      </div>
    );
  };

  renderThrobber(message?: string) {
    return (
      <React.Fragment>
        <Throbber loading={true} />
        {message ? <span> {message} </span> : null}
      </React.Fragment>
    );
  }

  getErrorKey = (row: number, columnId: string) => `${row}-${columnId}`;

  getErrorKeyFromCell = (row: CellInfo) => this.getErrorKey(row.index, row.column.id as string);

  setErrorState = (row: CellInfo, isValid: boolean) => {
    const { errors } = this.state;
    const key = this.getErrorKeyFromCell(row);
    errors[key] = !isValid;
    this.setState({ errors });
  };

  createOfflineDonations = async () => {
    const organizationId = this.props.selectedOrganization.id;
    const formattedCampaigns = this.formatCampaigns();
    const offlineDonations = this.props.templateData.map((row) => {
      const currentRow = { ...row };

      const classyCampaign = formattedCampaigns.find(
        (campaign: any) => campaign.value === Number(currentRow.campaignId),
      );
      if (classyCampaign) {
        currentRow.campaignName = classyCampaign.label;
      }

      if (row.fundraiserId && row.fundraiserType) {
        currentRow[row.fundraiserType] = row.fundraiserId;
      }

      return currentRow;
    });

    this.props.setLoadingOfflineDonations();
    try {
      const createOfflineDonationsResponse = await api.createOfflineDonations(offlineDonations, organizationId, {
        signal: this.apiAbortController.signal,
      });
      if (createOfflineDonationsResponse.success) {
        const { data } = createOfflineDonationsResponse;
        const { validationErrors } = data;

        if (validationErrors && validationErrors.length) {
          this.props.createOfflineDonationsFailure(validationErrors);
          const errorList = validationErrors.map((errorRow: OfflineDonationValidationError) => {
            // todo: allow a proper error
            const errorItems = errorRow.issues.map((errorItem: string) => errorItem);
            return `Row ${errorRow.index + 1}: Invalid ${errorItems}`;
          });
          this.setAlert(errorList);
        } else {
          if (typeof data === 'object') {
            this.props.createOfflineDonationsSuccess(data);
          }
          this.props.clearTemplateData();
        }
      } else {
        this.setAlert(constants.MESSAGES.ERROR_DEFAULT);
      }
    } catch (error: any) {
      this.props.createOfflineDonationsFailure(error);
      // TODO: this doesn't seem correct for axios
      if (error.statusCode === httpStatusCodes.GATEWAY_TIMEOUT) {
        this.setAlert(constants.FILE_PROCESSING_MSG);
        this.props.clearTemplateData();
      } else if (error.success === false && error.errors[0]) {
        this.setAlert(error.errors[0]);
      }
    }
  };

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />

        <h2 className="title-text">Offline Donations</h2>
        <div className="offline-donations__table-header-button-group">
          <div className="flexRow">
            <ClassyButton
              className="secondary-button offline-donations__table-header-button"
              title={this.hasData() ? 'Save for Later' : 'Download Template'}
              onClick={this.onDownloadTemplate}
            />

            <FileInput
              className="offline-donations__table-header-button"
              hideInput
              inputId="uploadedTemplate"
              buttonLabel="Upload Template"
              onOpenFileBrowser={(e: ChangeEvent<any>) => {
                e.preventDefault();
                const file = e.target.files[0];
                this.onUploadTemplate(file);
                e.target.value = null;
              }}
            />
          </div>
          <HelpComponent />
        </div>

        {!this.state.isOrgDataLoading ? this.renderTemplate() : this.renderThrobber('loading organization data...')}

        <DetailedErrors errors={this.state.rowErrors} />
      </div>
    );
  }
}

const mapStateToProps = (state: any): OfflineDonationsStoreProps => {
  const { selectedOrganization } = state.login;
  const {
    campaigns,
    createOfflineDonationsResponse,
    fundraiserPages,
    fundraiserTeams,
    loadingOfflineDonations,
    programDesignations,
    templateData,
    templateRow,
  } = state.offlineDonations;
  return {
    campaigns,
    createOfflineDonationsResponse,
    fundraiserPages,
    fundraiserTeams,
    loadingOfflineDonations,
    programDesignations,
    selectedOrganization,
    templateData,
    templateRow,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const {
    addTemplateRow,
    clearTemplateData,
    clearOrganizationData,
    createOfflineDonationsFailure,
    createOfflineDonationsSuccess,
    setCampaigns,
    setFundraiserPages,
    setFundraiserTeams,
    setLoadingOfflineDonations,
    setProgramDesignations,
    updateOfflineTemplate,
  } = OfflineDonationActions;
  const { selectOrganization } = LoginActions;

  return {
    addTemplateRow: (row: CellInfo) => dispatch(addTemplateRow(row)),
    clearOrganizationData: () => dispatch(clearOrganizationData()),
    clearTemplateData: () => dispatch(clearTemplateData()),
    createOfflineDonationsFailure: (createOfflineDonationsErrors: any) => {
      dispatch(createOfflineDonationsFailure(createOfflineDonationsErrors));
    },
    createOfflineDonationsSuccess: (createOfflineDonationsResponse: any) => {
      dispatch(createOfflineDonationsSuccess(createOfflineDonationsResponse));
    },
    selectOrganization: (organization: any) => dispatch(selectOrganization(organization)),
    setCampaigns: (campaigns: any) => dispatch(setCampaigns(campaigns)),
    setFundraiserPages: (fundraiserPages: any) => dispatch(setFundraiserPages(fundraiserPages)),
    setFundraiserTeams: (fundraiserTeams: any) => dispatch(setFundraiserTeams(fundraiserTeams)),
    setLoadingOfflineDonations: () => dispatch(setLoadingOfflineDonations()),
    setProgramDesignations: (programDesignations: any) => dispatch(setProgramDesignations(programDesignations)),
    updateOfflineTemplate: (data: any) => dispatch(updateOfflineTemplate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineDonations);
