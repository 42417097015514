import { RlcCommandLog, RlcCommandLogStatus } from '../../Services/Rlc/RlcCommandLog';
import React, { useCallback, useState } from 'react';
import useSWR from 'swr';
import ClassyTable from '../ClassyTable/ClassyTable';
import { CellInfo } from 'react-table';
import api from '../../Services/Api';
import {
  DateTimeCell,
  dropDownColumnFilter,
  DropDownFilter,
  DropDownFilterProps,
} from '../ClassyTable/ClassyTableUtils';
import LogDetailsModal from './LogDetails';

interface SortBy {
  id: string;
  desc: boolean;
}

interface FilterBy {
  id: string;
  value: string;
}

export interface LogFilter {
  currentPage: number; // 0-indexed current page
  pageSize: number;
  sortBy: Array<SortBy>;
  filterBy: Array<FilterBy>;
}

const rlcCommandLogStatuses = [
  RlcCommandLogStatus.Failed,
  RlcCommandLogStatus.Pending,
  RlcCommandLogStatus.Active,
  RlcCommandLogStatus.Completed,
];

const commandLogStatusFilter = dropDownColumnFilter(rlcCommandLogStatuses);

const fetcher = (url: string) => api.api.get(url).then((res) => res.data);

interface LogListProps {
  commandLogs: Array<RlcCommandLog>;
  onFilterChanged: (filter: LogFilter) => void;
  pageCount: number;
}

const LogList = ({ commandLogs, onFilterChanged, pageCount }: LogListProps) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [currentLog, setCurrentLog] = useState<RlcCommandLog | null>(null);
  const { data: taskTypes } = useSWR(`${api.apiUrl}rlc/logtypes`, fetcher);

  const onDetailsClosed = useCallback(() => {
    setDetailsOpen(false);
    setCurrentLog(null);
  }, []);
  const handleViewDetails = useCallback((commandLog: RlcCommandLog) => {
    setCurrentLog(commandLog);
    setDetailsOpen(true);
  }, []);

  const onFetchData = useCallback(
    async (state) => {
      const { sorted, filtered, page, pageSize } = state;
      const newFilter: LogFilter = {
        currentPage: page,
        sortBy: sorted,
        filterBy: filtered,
        pageSize: pageSize,
      };
      onFilterChanged(newFilter);
    },
    [onFilterChanged],
  );
  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'Command',
      width: 250,
      resize: true,
      filterable: true,
      accessor: 'command',
      filterMethod: dropDownColumnFilter(taskTypes || []),
      Filter: ({ filter, onChange }: DropDownFilterProps) => (
        <DropDownFilter options={taskTypes || []} filter={filter} onChange={onChange} />
      ),
    },
    {
      Header: 'Status',
      width: 100,
      resize: true,
      filterable: true,
      accessor: 'status',
      filterMethod: commandLogStatusFilter,
      Filter: ({ filter, onChange }: DropDownFilterProps) => (
        <DropDownFilter options={rlcCommandLogStatuses} filter={filter} onChange={onChange} />
      ),
    },
    {
      Header: 'Created',
      width: 250,
      resize: true,
      filterable: false,
      accessor: 'createdAt',
      Cell: (row: CellInfo) => <DateTimeCell value={row.value} />,
    },
    {
      Header: '',
      width: 250,
      resize: true,
      filterable: false,
      accessor: 'createdAt',
      Cell: (row: CellInfo) => (
        <button className="btn-link" onClick={() => handleViewDetails(row.row._original)}>
          View
        </button>
      ),
    },
  ];

  return (
    <>
      {currentLog && (
        <LogDetailsModal isOpen={detailsOpen} log={currentLog} onCancel={onDetailsClosed} title="Log Details" />
      )}
      <ClassyTable
        showWhenEmpty
        columns={columns}
        data={commandLogs}
        // defaultPageSize={20}
        loading={false}
        manualPagination={true}
        onFetchData={onFetchData}
        pages={pageCount}
        pageSizeOptions={[20, 50, 100]}
        resizable={true}
      />
    </>
  );
};
export default LogList;
