import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import constants from '../../Helpers/constants';
import './DateInput.scss';

class DateInput extends Component {
  render() {
    const { className, maxDate, minDate, onChange, placeholder, popperPlacement, selected } = this.props;

    return (
      <div className="date-input">
        <DatePicker
          className={className}
          selected={selected ? moment(selected, constants.DATES.DATE_FORMAT) : undefined}
          onChange={onChange}
          onChangeRaw={(e) => e.preventDefault()}
          placeholderText={placeholder}
          popperPlacement={popperPlacement}
          minDate={minDate}
          maxDate={maxDate}
        />
        <span className="fa fa-calendar date-input__icon"></span>
      </div>
    );
  }
}

DateInput.propTypes = {
  className: PropTypes.string,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  popperPlacement: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default DateInput;
