import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import papaParse from 'papaparse';
import { saveAs } from 'file-saver';
import ReactTable from 'react-table';
import ClassyButton from '../ClassyButton/ClassyButton';
import constants from '../../Helpers/constants';
import 'react-table/react-table.css';
import './ClassyTable.scss';

export class ClassyTable extends Component {
  constructor(props) {
    super(props);

    this.exportReport = this.exportReport.bind(this);
  }

  exportReport() {
    const { exportFilename, formattedExportData } = this.props;

    const csv = papaParse.unparse(formattedExportData);
    const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const csvName = `${exportFilename}_${moment().format(constants.DATES.REPORT_EXPORT_FILENAME_DATE_FORMAT)}.csv`;

    saveAs(csvBlob, csvName);
  }

  render() {
    const {
      className,
      clearTable,
      columns,
      data,
      defaultPageSize,
      filterable,
      FooterLeftComponent,
      formattedExportData,
      getTdProps,
      HeaderLeftComponent,
      loading,
      manualPagination,
      onFetchData,
      onPageChange,
      onPageSizeChange,
      pages,
      pageSize,
      pageSizeOptions,
      resizable,
      showWhenEmpty,
    } = this.props;

    return (
      <div id="classy-table" className={(data && data.length) || showWhenEmpty ? 'classy-table' : 'hide'}>
        {HeaderLeftComponent ? (
          <div className={`flexRow ${HeaderLeftComponent ? 'contentSpaced' : 'contentRight'}`}>
            {HeaderLeftComponent}
            {formattedExportData ? (
              <ClassyButton
                className="secondary-button margin-vertical-large"
                disabled={data ? !data.length : true}
                title="Export Report"
                onClick={this.exportReport}
              />
            ) : null}
          </div>
        ) : null}
        <ReactTable
          className={className}
          data={data || []}
          columns={columns || []}
          PreviousComponent={(props) => (
            <div className={FooterLeftComponent ? 'classy-table__footer-left' : null}>
              {FooterLeftComponent}
              <button {...props} className="table-button">
                Previous
              </button>
            </div>
          )}
          NextComponent={(props) => (
            <div>
              <button {...props} className="table-button">
                Next
              </button>
              {clearTable ? (
                <button className="padding-large pull-right btn-link" onClick={clearTable}>
                  Clear Table
                </button>
              ) : (
                ''
              )}
            </div>
          )}
          getTdProps={getTdProps}
          minRows={0}
          loading={loading}
          defaultPageSize={defaultPageSize}
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}
          pages={pages}
          manual={manualPagination}
          onFetchData={onFetchData}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          resizable={resizable}
          filterable={filterable}
        />
      </div>
    );
  }
}

ClassyTable.propTypes = {
  className: PropTypes.string,
  clearTable: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultPageSize: PropTypes.number,
  filterable: PropTypes.bool,
  FooterLeftComponent: PropTypes.any,
  formattedExportData: PropTypes.array,
  getTdProps: PropTypes.func,
  HeaderLeftComponent: PropTypes.any,
  pageSizeOptions: PropTypes.array,
  exportFilename: PropTypes.string,
  showWhenEmpty: PropTypes.bool,
  loading: PropTypes.bool,
};

export default ClassyTable;
