import React, { useCallback, useState, useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { APIResponse } from '../../Typings/Services/Api';
import api from '../../Services/Api';
import CampaignList from './CampaignList';
import { RlcCampaign } from '../../Services/Rlc/RlcCampaign';
import ClassyButton from '../ClassyButton/ClassyButton';
import { useSelector } from 'react-redux';
import AddCampaignModal, { AddRlcCampaignForm } from './AddCampaign';
import EditCampaignModal from './EditCampaign';
import { useDesignationCount } from './RlcHooks';

const getRlcCampaignsUrl = (rlcGroupId: number, organizationId: number) =>
  `${api.apiUrl}rlc/${rlcGroupId}/organizations/${organizationId}/campaigns`;

const fetcher = (url: string) => api.api.get(url).then((res) => res);

const Campaigns = ({ match }: { match: any }) => {
  const userRlcGroup = useSelector<any, number | undefined>((state) => state.login?.user?.rlcGroupId);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const [isInFlight, setIsInFlight] = useState<boolean>(false);
  const [campaignError, setCampaignError] = useState<string | null>(null);
  const [isAddCampaignModalOpen, setIsAddCampaignModalOpen] = useState<boolean>(false);
  const [isEditCampaignModalOpen, setIsEditCampaignModalOpen] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<RlcCampaign | null>(null);
  const campaignDesignationCount = useDesignationCount(userRlcGroup, true);

  const selectedOrganizationId = useMemo(() => match?.params?.id, [match?.params]);

  const onCancelAddCampaign = useCallback(() => {
    setIsAddCampaignModalOpen(false);
    setCampaignError(null);
  }, []);

  const onCancelEditCampaign = useCallback(() => {
    setIsEditCampaignModalOpen(false);
    setCampaignError(null);
  }, []);

  const onAddCampaign = useCallback(
    async (addCampaign: AddRlcCampaignForm) => {
      if (!userRlcGroup) {
        return;
      }

      setCampaignError(null);
      setIsInFlight(true);
      if (selectedOrganizationId) {
        try {
          const result = await api.addRlcCampaign(selectedOrganizationId, userRlcGroup, addCampaign);
          if (result.success) {
            await mutate(getRlcCampaignsUrl(userRlcGroup, selectedOrganizationId));
            setIsAddCampaignModalOpen(false);
          } else {
            console.error('== errors ');
            console.error(result.errors.join(', '));
            setCampaignError(result.errors.join(', '));
          }
        } catch (e) {
          console.error(e);
          console.error((e as any).errors.join(', '));
          setCampaignError((e as any).errors.join(', '));
        }
      }
      setIsInFlight(false);
    },
    [userRlcGroup, selectedOrganizationId],
  );

  const onEditCampaign = useCallback(
    async (editCampaign: RlcCampaign) => {
      if (!userRlcGroup) {
        return;
      }

      setIsInFlight(true);
      if (selectedOrganizationId) {
        const { id, classyCampaignId, usesZipCode } = editCampaign;
        try {
          const result = await api.modifyRlcCampaign(selectedOrganizationId, userRlcGroup, id, {
            classyCampaignId,
            usesZipCode,
          });
          if (result.success) {
            await mutate(getRlcCampaignsUrl(userRlcGroup, selectedOrganizationId));
            setIsEditCampaignModalOpen(false);
          } else {
            console.error('== onEditCampaign ');
            console.error(result.errors.join(', '));
            setCampaignError(result.errors.join(', '));
          }
        } catch (e) {
          console.error(e);
          console.error((e as any).errors.join(', '));
          setCampaignError((e as any).errors.join(', '));
        }
      }
      setIsInFlight(false);
    },
    [userRlcGroup, selectedOrganizationId],
  );

  const onRemoveCampaign = useCallback(
    async (campaignId: number) => {
      if (!userRlcGroup) {
        return;
      }

      setIsInFlight(true);
      if (selectedOrganizationId) {
        try {
          const result = await api.deleteRlcCampaign(selectedOrganizationId, userRlcGroup, campaignId);
          if (result.success) {
            await mutate(getRlcCampaignsUrl(userRlcGroup, selectedOrganizationId));
            setIsEditCampaignModalOpen(false);
          } else {
            console.error('== onRemoveCampaign ');
            console.error(result.errors.join(', '));
            setCampaignError(result.errors.join(', '));
          }
        } catch (e) {
          console.error(e);
          console.error((e as any).errors.join(', '));
          setCampaignError((e as any).errors.join(', '));
        }
      }

      setIsInFlight(false);
    },
    [userRlcGroup, selectedOrganizationId],
  );

  const { /* error: swrError, */ data: swrData /* isValidating */ } = useSWR<APIResponse<RlcCampaign[]>>(
    selectedOrganizationId && userRlcGroup ? getRlcCampaignsUrl(userRlcGroup, selectedOrganizationId) : null,
    fetcher as any,
  );

  const onEditCampaignClicked = useCallback((editCampaign: RlcCampaign) => {
    setSelectedCampaign(editCampaign);
    setIsEditCampaignModalOpen(true);
  }, []);
  return (
    <div>
      <AddCampaignModal
        title="Add Campaign"
        isOpen={isAddCampaignModalOpen}
        onCancel={onCancelAddCampaign}
        onSave={onAddCampaign}
        error={campaignError}
        isInFlight={isInFlight}
      />
      {selectedCampaign && (
        <EditCampaignModal
          title={`Edit Campaign: ${selectedCampaign.name}`}
          isOpen={isEditCampaignModalOpen}
          onCancel={onCancelEditCampaign}
          onSave={onEditCampaign}
          onRemove={onRemoveCampaign}
          error={campaignError}
          isInFlight={isInFlight}
          campaign={selectedCampaign}
          campaignDesignationCount={campaignDesignationCount}
        />
      )}
      <h2 className="title-text">Manage Campaigns</h2>

      {error && <div className="rlcDesignations__error_text">{error}</div>}
      <ClassyButton title="Add Campaign" onClick={() => setIsAddCampaignModalOpen(true)} />
      {swrData && swrData.success && (
        <CampaignList campaigns={swrData.data} onCampaignEditRequested={onEditCampaignClicked} />
      )}
    </div>
  );
};

export default Campaigns;
