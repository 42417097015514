import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';
import AppActions from '../../Redux/App.redux';
import FileUploader from '../FileUploader/FileUploader';
import ClassyButton from '../ClassyButton/ClassyButton';
import ClassyTable from '../ClassyTable/ClassyTable';
import Card from '../Card/Card';
import Throbber from '../Throbber/Throbber';

export class NonMonthlyRecurringCharges extends Component {
  constructor(props) {
    super(props);

    this.filterNonMonthlyRecurringData = this.filterNonMonthlyRecurringData.bind(this);
    this.renderImportResult = this.renderImportResult.bind(this);
    this.renderThrobber = this.renderThrobber.bind(this);
    this.handleCloseDuplicates = this.handleCloseDuplicates.bind(this);
  }

  componentWillUnmount() {
    const { clearRecurringResponse } = this.props;
    clearRecurringResponse();
  }

  filterNonMonthlyRecurringData(data) {
    let filteredData = [];
    if (data) {
      filteredData = data.map((donorProfile, index) => {
        const filtered = {
          index: index + 1,
          status: donorProfile.errorMessage || 'success',
          batchId: donorProfile.BatchId,
          donorId: donorProfile.id,
          firstName: donorProfile.firstName,
          lastName: donorProfile.lastName,
        };

        return filtered;
      });
    }
    return filteredData;
  }

  renderThrobber() {
    const { loading } = this.props;

    return <Throbber loading={loading} />;
  }

  handleCloseDuplicates() {
    const { clearRecurringResponse } = this.props;
    clearRecurringResponse();
  }

  renderDuplicateItems(duplicates) {
    return duplicates.map((record) => (
      <li key={record.Token + record['Transaction ID']}>
        {record['Transaction ID']} / {record['Billing Email']}
      </li>
    ));
  }

  renderDuplicateModal() {
    const { recurringResponse } = this.props;

    let duplicates = [];
    if (recurringResponse && recurringResponse.data && recurringResponse.data.errors) {
      duplicates = recurringResponse.data.errors;
    }

    return (
      <Modal show={duplicates.length > 0} onHide={this.handleCloseDuplicates}>
        <Modal.Header closeButton>
          <Modal.Title>Duplicate Profiles Detected</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            The following Transaction ID / Billing Email combinations were already uploaded:
            <div className="padding-medium">{this.renderDuplicateItems(duplicates)}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ClassyButton title={'Close'} onClick={this.handleCloseDuplicates} />
        </Modal.Footer>
      </Modal>
    );
  }

  renderImportResult() {
    const { recurringResponse } = this.props;
    let result;

    if (recurringResponse) {
      const { data } = recurringResponse;
      const recurringResultColumns = [
        {
          Header: 'Row #',
          Cell: (row) => <div>{typeof row.index !== 'undefined' ? row.index + 1 : null}</div>,
          width: 75,
        },
        {
          Header: 'Status',
          accessor: 'errorMessage',
          Cell: (row) =>
            row.value ? (
              <div>
                <i className="fa fa-times status-icon--error" />
                <span>{`: ${row.value}`}</span>
              </div>
            ) : (
              <i className="fa fa-check status-icon--success" />
            ),
        },
        {
          Header: 'Batch ID',
          accessor: 'BatchId',
          width: 100,
        },
        {
          Header: 'Donor ID',
          accessor: 'id',
          width: 100,
        },
        {
          Header: 'First Name',
          accessor: 'firstName',
        },
        {
          Header: 'Last Name',
          accessor: 'lastName',
        },
      ];
      let failedImports = [];
      let successfulImports = [];
      if (data && Array.isArray(data)) {
        failedImports = data.filter((el) => el.errorMessage);
        successfulImports = data.filter((el) => !el.errorMessage);
      }

      result = Array.isArray(data) && (
        <div>
          {data ? (
            <div>
              <Card
                messageTop={
                  successfulImports.length ? `${successfulImports.length} donor profile(s) successfully imported` : null
                }
                messageBottom={failedImports.length ? `${failedImports.length} donor profile import(s) failed` : null}
              />
              <Prompt
                when={!!data.length}
                message="Are you sure you want to leave? Your results will not be retained."
              />
            </div>
          ) : null}
          {this.renderThrobber()}
          <ClassyTable
            data={data || []}
            exportFilename="Donor_Profiles"
            formattedExportData={this.filterNonMonthlyRecurringData(data)}
            columns={recurringResultColumns}
          />
        </div>
      );
    }
    return result;
  }

  render() {
    const { onRecurringResponse, selectedOrganization: { id: organizationId } = {} } = this.props;

    return (
      <div id="nonMonthlyRecurringCharges">
        <h2 className="title-text">Recurring Donor Import</h2>

        <FileUploader
          route={`templates/${organizationId}/${process.env.REACT_APP_CLASSY_SERVICES_API_DONOR_PROFILE}`}
          onParentComplete={onRecurringResponse}
        />

        {this.renderImportResult()}
        {this.renderDuplicateModal()}
      </div>
    );
  }
}

NonMonthlyRecurringCharges.propTypes = {
  clearRecurringResponse: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  onRecurringResponse: PropTypes.func.isRequired,
  recurringResponse: PropTypes.object,
  selectedOrganization: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { selectedOrganization } = state.login;
  const { loading, recurringResponse } = state.app;

  return {
    loading,
    recurringResponse,
    selectedOrganization,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { clearRecurringResponse, onRecurringResponse } = AppActions;

  return {
    clearRecurringResponse: () => dispatch(clearRecurringResponse()),
    onRecurringResponse: (response) => dispatch(onRecurringResponse(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NonMonthlyRecurringCharges);
