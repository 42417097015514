import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from '../../../Services/Api';
import history from '../../../Router/history';
import constants from '../../../Helpers/constants';
import utilities from '../../../Helpers/utilities';
import ClassyAlert from '../../ClassyAlert/ClassyAlert';
import ClassyTable from '../../ClassyTable/ClassyTable';

class RecurringProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      showAlert: false,
      recurringProfiles: [],
    };

    this.renderRecurringProfilesTable = this.renderRecurringProfilesTable.bind(this);
    this.onClickEditDonor = this.onClickEditDonor.bind(this);
    this.setAlert = this.setAlert.bind(this);
  }

  componentDidMount() {
    this.getRecurringProfiles();
  }

  componentDidUpdate(prevProps) {
    const { selectedOrganization } = this.props;
    if (prevProps.selectedOrganization !== selectedOrganization) {
      this.getRecurringProfiles();
    }
  }

  setAlert(alertMessage, showAlert = true) {
    this.setState({ alertMessage, showAlert });
  }

  async getRecurringProfiles() {
    const organizationId = this.props.selectedOrganization.id;
    let response;
    try {
      const getRecurringResponse = await api.getRecurringProfiles(organizationId);
      if (getRecurringResponse.success) {
        const recurringProfiles = getRecurringResponse.data;
        this.setState({ recurringProfiles });
      } else {
        response =
          'Unfortunately, we ran into a problem while processing this request - please contact the administrator';
      }
    } catch (exception) {
      response = exception.errors;
    }
    if (response) {
      this.setAlert(response);
    }
  }

  filterRecurringProfilesData() {
    const data = this.state.recurringProfiles;
    let filteredData = [];
    if (data) {
      filteredData = data.map((recurringProfiles, index) => {
        const filtered = {
          index: index + 1,
          id: recurringProfiles.id,
          paymentTokenId: recurringProfiles.paymentTokenId,
          firstName: recurringProfiles.firstName,
          lastName: recurringProfiles.lastName,
          email: recurringProfiles.email,
          status:
            (recurringProfiles.donationStatus === null && 'NA') ||
            (recurringProfiles.donationStatus ? 'Enabled' : 'Disabled'),
          updatedAt: moment.utc(recurringProfiles.updatedAt).local().format(constants.DATES.TABLE_DATE_FORMAT),
          updatedBy: recurringProfiles.updatedBy,
        };

        return filtered;
      });
    }
    return filteredData;
  }

  onClickEditDonor(selectedDonorId) {
    history.push({ pathname: `/admin/donors/${selectedDonorId}/edit` }, { showRecurringProfile: true });
  }

  renderRecurringProfilesTable() {
    const { recurringProfiles } = this.state;
    const columns = [
      {
        width: 50,
        Cell: (row) => (
          <button className="btn-link" onClick={() => this.onClickEditDonor(row.row.id)}>
            Edit
          </button>
        ),
        filterable: false,
      },
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
        filterable: false,
      },
      {
        Header: 'Payment Token Id',
        accessor: 'paymentTokenId',
        width: 200,
        filterable: true,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Last Name ',
        accessor: 'lastName',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Email ',
        accessor: 'email',
        filterMethod: utilities.filterStringHelper,
      },
      {
        Header: 'Status',
        accessor: 'donationStatus',
        width: 150,
        Cell: (row) => (row.value === null && 'NA') || (row.value ? 'Enabled' : 'Disabled'),
        filterable: false,
      },
      {
        Header: 'Updated At',
        accessor: 'updatedAt',
        Cell: (row) => (row.value ? moment.utc(row.value).local().format(constants.DATES.TABLE_DATE_FORMAT) : null),
        filterable: false,
      },
      {
        Header: 'Updated By',
        accessor: 'updatedBy',
        Cell: (row) => <div>{row.value || null}</div>,
        filterable: false,
      },
    ];

    return (
      <div>
        <ClassyTable
          filterable
          showWhenEmpty
          data={recurringProfiles || []}
          columns={columns || []}
          exportFilename={'RecurringProfiles'}
          formattedExportData={this.filterRecurringProfilesData()}
          defaultPageSize={15}
          pageSizeOptions={[15, 25, 50, 100]}
        />
        {recurringProfiles && !recurringProfiles.length ? <span>No Recurring Profiles found.</span> : null}
      </div>
    );
  }

  render() {
    return (
      <div>
        <ClassyAlert
          show={this.state.showAlert}
          alertMessage={this.state.alertMessage}
          onHide={() => this.setState({ alertMessage: '', showAlert: false })}
        />
        <h2 className="title-text">Recurring Profiles</h2>
        {this.renderRecurringProfilesTable()}
      </div>
    );
  }
}

RecurringProfiles.propTypes = {
  organizations: PropTypes.array.isRequired,
  selectedOrganization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { organizations, selectedOrganization } = state.login;

  return {
    organizations,
    selectedOrganization,
  };
};

export default connect(mapStateToProps)(RecurringProfiles);
